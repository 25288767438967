import React, { useEffect, useState } from 'react';
import {
  Col, Row, notification, Button,
} from 'antd';
import { useParams, useNavigate } from 'react-router-dom';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { FaArrowRightLong } from 'react-icons/fa6';
import { IoMdStopwatch } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import SeasonStats from '../components/SeasonStats';
import ConfirmAction from '../components/ConfirmAction';
import AdvanceConflicts from '../components/AdvanceConflicts';
import '../css/season.css';

function Season() {
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  let seasonID = 0;
  const { id } = useParams();
  seasonID = id;

  const [seasonName, setSeasonName] = useState('');
  const [seasonStartDate, setSeasonStartDate] = useState('');
  const [seasonEndDate, setSeasonEndDate] = useState('');
  const [seasonPhases, setSeasonPhases] = useState([]);
  const [validCandidates, setValidCandidates] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showConflicts, setShowConflicts] = useState(false);
  const [phaseConflicts, setPhaseConflicts] = useState('[]');
  const [phaseNameToAdvance, setPhaseNameToAdvance] = useState('');
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  const transformDate = (date) => {
    const dateObj = new Date(date);

    const day = String(dateObj.getUTCDate()).padStart(2, '0');
    const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0');
    const year = dateObj.getUTCFullYear();

    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  };

  const fetchSeason = async () => {
    try {
      const response = await axios.get(
        `/api/season/${seasonID}`,
      );
      setSeasonName(response.data.name);
      setSeasonStartDate(transformDate(response.data.beginDate));
      setSeasonEndDate(transformDate(response.data.endDate));
      setSeasonPhases(response.data.phases);
    } catch (error) {
      openNotificationWithIcon('error', 'Error fetching role');
    }
  };

  const fetchNumberOfValidCandidates = async () => {
    try {
      const response = await axios.get(
        '/api/user/validCandidates',
      );
      setValidCandidates(response.data);
    } catch (error) {
      openNotificationWithIcon('error', 'Error fetching number of validated candidates');
    }
  };

  useEffect(() => {
    fetchSeason();
    fetchNumberOfValidCandidates();
  }, []);

  const currentPhase = seasonPhases.find((phase) => phase.current);
  const nextPhase = seasonPhases.find((phase) => !phase.current && phase.id > currentPhase.id);

  const advancePhase = () => {
    axios.post(
      `api/season/${seasonID}/advance`,
    )
      .then(() => {
        openNotificationWithIcon('success', phaseNameToAdvance + t('confirmModal.success_advance'));
        fetchSeason();
      })
      .catch(() => {
        openNotificationWithIcon('error', t('confirmModal.error_advance') + phaseNameToAdvance);
      });
  };

  const showConfirmModal = async () => {
    setPhaseNameToAdvance(currentPhase.name);
    await axios.get(`api/season/${seasonID}/can-advance`)
      .then((response) => {
        if (response.data.canDelete) {
          setShowConfirmation(true);
        } else {
          setPhaseConflicts(JSON.stringify(response.data.conflicts));
          setShowConflicts(true);
        }
      })
      .catch((error) => {
        console.error('Error fetching Departments:', error);
      });
  };

  const showDeleteConfirmModal = () => {
    setShowDeleteConfirmation(true);
  };

  const deleteSeason = async () => {
    if (seasonID) {
      try {
        await axios.delete(`/api/season/${seasonID}`);
        // Show success
        openNotificationWithIcon('success', seasonName + t('confirmModal.success_delete'));
      } catch (error) {
        console.error('Error deleting seasons:', error);
        openNotificationWithIcon('error', t('confirmModal.error_delete') + seasonName);
      }
    }
    navigate('/seasons');
  };

  return (
    <div>
      <Row className="season-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={24} className="season-page">
          <h1 className="season-name">{seasonName}</h1>
          <div className="season-page-body">
            <div className="phases-div">
              {seasonPhases.filter((phase) => phase.current).map((phase) => (
                <div className="curent-phase-div">
                  <div>
                    <h3 className="curent-phase-title">{t('season.current_phase')}</h3>
                    <p className="curent-phase-name">{phase.name}</p>
                    <button className="curent-phase-change-schedules-button" type="button">
                      <div className="icon-container">
                        <FaRegCalendarAlt className="calendar-icon" />
                      </div>
                      <div className="text-container">
                        {t('season.change_shedules')}
                      </div>
                    </button>
                  </div>
                </div>
              ))}
              <div className="next-phase-div">
                {nextPhase ? (
                  <div>
                    <h3 className="next-phase-title">{t('season.next_phase')}</h3>
                    <p className="next-phase-name">{nextPhase.name}</p>
                    <div className="next-phase-buttons-div">
                      <button className="curent-phase-change-schedules-button" type="button" onClick={showConfirmModal}>
                        <div className="icon-container">
                          <FaArrowRightLong className="season-icons" />
                        </div>
                        <div className="text-container">
                          {t('season.advance_phase')}
                        </div>
                      </button>
                      <button className="curent-phase-change-schedules-button" type="button">
                        <div className="icon-container">
                          <IoMdStopwatch className="season-icons" />
                        </div>
                        <div className="text-container">
                          {t('season.generate_schedules')}
                        </div>
                      </button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <h3 className="next-phase-title">{t('season.next_phase')}</h3>
                    <p className="next-phase-name">{t('season.no_next_phase')}</p>
                  </div>
                )}
              </div>
            </div>
            <div className="middle-section">
              <div className="dates-div">
                <div className="start-date-div">
                  <h3 className="start-date-title">
                    {t('season.current_phase_start_date')}
                    :
                  </h3>
                  <div className="start-date-text-div">
                    <FaRegCalendarAlt className="date-icon" />
                    <div className="start-date-text">
                      <p>{seasonStartDate}</p>
                    </div>
                  </div>
                </div>
                <div className="end-date-div">
                  <h3 className="end-date-title">
                    {t('season.current_phase_end_date')}
                    :
                  </h3>
                  <div className="end-date-text-div">
                    <FaRegCalendarAlt className="date-icon" />
                    <div className="end-date-text">
                      <p>{seasonEndDate}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="season-info-div">
                <h3 className="phases-to-open-title">{t('season.phases_to_open')}</h3>
                {nextPhase ? ( // eslint-disable-line
                  seasonPhases.filter((phase) => !phase.current && phase.id > nextPhase.id).length > 0 ? ( // eslint-disable-line
                    seasonPhases.filter((phase) => !phase.current && phase.id > nextPhase.id).map((phase) => ( // eslint-disable-line
                      <p key={phase.id}>{phase.name}</p>
                    ))
                  ) : (
                    <p>{t('season.no_phases_to_open')}</p>
                  )
                ) : (
                  <p>{t('season.no_phases_to_open')}</p>
                )}
              </div>
            </div>
            <div className="final-section">
              <SeasonStats candidates={validCandidates} cvPhases={50} dynamics={25} /> {/* eslint-disable-line */}
              <div className="season-buttons-div">
                <Button className="delete-season-button">Acabar Temporada</Button>
                <Button className="delete-season-button" onClick={showDeleteConfirmModal}>Eliminar Temporada</Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <AdvanceConflicts
        open={showConflicts}
        setOpen={setShowConflicts}
        name={phaseNameToAdvance}
        conflicts={phaseConflicts}
      />
      <ConfirmAction
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={advancePhase}
      />
      <ConfirmAction
        open={showDeleteConfirmation}
        setOpen={setShowDeleteConfirmation}
        onConfirm={deleteSeason}
      />
    </div>
  );
}

export default Season;
