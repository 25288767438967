import React, { useState, useEffect, useContext } from 'react';
import {
  Layout,
  Menu,
  Button,
  Collapse,
  Tooltip,
} from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../contexts/AuthContext';
import HamburguerLines from '../images/open_menu_icon.svg';
import CloseMenu from '../images/close_icon.svg';
import HomepageIcon from '../images/homepage_icon.svg';
import UsersIcon from '../images/users_icon.svg';
import RolesIcon from '../images/roles_icon.svg';
import PersonalizeIcon from '../images/personalize_icon.svg';
import CandidatesIcon from '../images/candidates_icon.svg';
import SeasonsIcon from '../images/seasons_icon.svg';
import JobsIcon from '../images/jobs_icon.svg';
import DepartmentsIcon from '../images/departments_icon.svg';
import ProfileIcon from '../images/profile_icon.svg';
import RecruitmentIcon from '../images/recruitment_icon.svg';
import OverallIcon from '../images/overall_icon.svg';
import OverallPersonalizationIcon from '../images/overall_personalization_icon.svg';
import LogoutIcon from '../images/logout_icon_white.svg';
import TranslateButton from './TranslateButton';
import ConfirmAction from './ConfirmAction';
import axios from '../axios';
import '../css/navbar.css';

import { AbilityContext } from '../Abilities/Can';

const { Sider } = Layout;

const { Panel } = Collapse;

function Navbar() {
  const navigate = useNavigate();
  const storedCollapsed = localStorage.getItem('navbarCollapsed');
  const [collapsed, setCollapsed] = useState(storedCollapsed === 'true');
  const [hovered, setHovered] = useState(false);
  const [t] = useTranslation('global');
  const location = useLocation();
  const ability = useContext(AbilityContext);
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);

  // Persist the collapsed state through pages
  useEffect(() => {
    localStorage.setItem('navbarCollapsed', collapsed);
    axios.get(
      '/api/user/profile',
    )
      .then((response) => {
        if (response.status === 200) {
          setUserType(response.data.type);
          setUserId(response.data.user.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [collapsed]);

  const handleMouseEnter = () => {
    if (collapsed) {
      setHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (collapsed) {
      setHovered(false);
    }
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        '/api/user/logout',
      );
      if (response.status === 200) {
        logout();
        navigate('/login');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const showConfirmation = () => {
    setOpen(true);
  };

  // Navbar items
  const items = [
    {
      label: t('menu.homepage'), key: '1', icon: <img src={HomepageIcon} alt="homepage icon" className="navbar-icon" />, link: '/', canAccess: () => true,
    },
    {
      label: t('menu.profile'), key: '2', icon: <img src={ProfileIcon} alt="profile icon" className="navbar-icon" />, link: ability.can('update', 'Users') ? `/user/${userId}` : '/profile', canAccess: () => userType === 'user',
    },
    {
      label: t('menu.profile'), key: '2', icon: <img src={ProfileIcon} alt="profile icon" className="navbar-icon" />, link: `/candidate/${userId}`, canAccess: () => userType === 'candidate',
    },
    {
      label: t('menu.users'), key: '3', icon: <img src={UsersIcon} alt="users icon" className={collapsed ? 'navbar-icon-collapse users-icon-collapse' : 'navbar-icon-collapse users-icon'} />, link: '/users', canAccess: () => ability.can('read', 'Users') && userType === 'user',
    },
    {
      label: t('menu.roles'), key: '4', icon: <img src={RolesIcon} alt="roles icon" className="navbar-icon-collapse" />, link: '/roles', canAccess: () => ability.can('read', 'Roles'),
    },
    {
      label: t('menu.general'), key: '5', icon: <img src={OverallPersonalizationIcon} alt="personalize icon" className="navbar-icon-collapse2" />, link: '/settings', canAccess: () => ability.can('read', 'Users') && userType === 'user',
    },
    {
      label: t('menu.departments'), key: '6', icon: <img src={DepartmentsIcon} alt="candidates icon" className="navbar-icon-collapse" />, link: '/departments', canAccess: () => ability.can('read', 'Departments'),
    },
    {
      label: t('menu.seasons'), key: '7', icon: <img src={SeasonsIcon} alt="seasons icon" className="navbar-icon-collapse2" />, link: '/seasons', canAccess: () => ability.can('read', 'Seasons'),
    },
    {
      label: t('menu.jobs'), key: '8', icon: <img src={JobsIcon} alt="jobs icon" className="navbar-icon-collapse2" />, link: '/jobs', canAccess: () => ability.can('read', 'Jobs'),
    },
    {
      label: t('menu.application_forms'), key: '9', icon: <img src={CandidatesIcon} alt="applicationForms icon" className="navbar-icon-collapse2" />, link: '/applicationForms', canAccess: () => ability.can('read', 'Application Forms'),
    },
  ];

  const currentKey = items.find((item) => item.link === location.pathname)?.key;

  return (
    <div>
      <Layout>
        <Sider
          trigger={null}
          collapsible
          collapsed={collapsed && !hovered}
          className={(collapsed && !hovered) ? 'navbar-menu collapsed' : 'navbar-menu wide'}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="toggle-menu-buttons">
            {(!collapsed || (collapsed && hovered)) && <TranslateButton className="translate-button-navbar" /> }
            <Button
              type="text"
              className={(collapsed && !hovered) ? 'toggle-menu-button center' : 'toggle-menu-button right'}
              icon={<img src={collapsed ? HamburguerLines : CloseMenu} alt="toggle-menu" className="toggle-menu-icon" />}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[currentKey]}>
            {items.slice(0, 3).map((item) => (
              item.canAccess() && (
                <Menu.Item key={item.key} icon={item.icon}>
                  <Link to={item.link}>{item.label}</Link>
                </Menu.Item>
              )
            ))}
          </Menu>
          { userType === 'user' && (ability.can('read', 'Users') || ability.can('read', 'Roles') || ability.can('read', 'all')) ? (
            <Collapse>
              <Panel
                header={
                (
                  <Tooltip className="collapse-tooltip" title={t('menu.general')} placement="right" align={{ offset: [30, 0] }}>
                    <div className="panel-header-container">
                      <img src={OverallIcon} alt="roles icon" className={collapsed ? 'panel-navbar-icon-collapse' : 'panel-navbar-icon'} />
                      {(!collapsed || (collapsed && hovered)) ? <span className="panel-header">{t('menu.general')}</span> : null}
                    </div>
                  </Tooltip>
                )
              }
                key="1"
              >
                <Menu mode="inline" selectedKeys={[currentKey]}>
                  {items.filter((item) => ['3', '4', '6'].includes(item.key)).map((item) => (
                    item.canAccess() && (
                      <Menu.Item key={item.key} icon={item.icon}>
                        <Link className={(collapsed && !hovered) ? '' : 'panel-link-title'} to={item.link}>{item.label}</Link>
                      </Menu.Item>
                    )
                  ))}
                </Menu>
              </Panel>
            </Collapse>
          ) : null }
          { userType === 'user' && (ability.can('read', 'Seasons') || ability.can('read', 'Jobs') || ability.can('read', 'Application Forms')) ? (
            <Collapse>
              <Panel
                header={
                (
                  <Tooltip className="collapse-tooltip" title={t('menu.recruitment')} placement="right" align={{ offset: [30, 0] }}>
                    <div className="panel-header-container">
                      <img src={RecruitmentIcon} alt="roles icon" className={collapsed ? 'panel-navbar-icon-collapse' : 'panel-navbar-icon'} />
                      {(!collapsed || (collapsed && hovered)) ? (
                        <span className="panel-header">{t('menu.recruitment')}</span>
                      ) : null}
                    </div>
                  </Tooltip>
                )
              }
                key="1"
              >
                <Menu mode="inline" selectedKeys={[currentKey]}>
                  {items.filter((item) => ['8', '7', '9'].includes(item.key)).map((item) => (
                    item.canAccess() && (
                      <Menu.Item key={item.key} icon={item.icon}>
                        <Link className={(collapsed && !hovered) ? '' : 'panel-link-title'} to={item.link}>{item.label}</Link>
                      </Menu.Item>
                    )
                  ))}
                </Menu>
              </Panel>
            </Collapse>
          ) : null }
          { userType === 'user' && ability.can('read', 'Users') ? (
            <Collapse>
              <Panel
                header={
                (
                  <Tooltip className="collapse-tooltip" title={t('menu.recruitment')} placement="right" align={{ offset: [30, 0] }}>
                    <div className="panel-header-container">
                      <img src={PersonalizeIcon} alt="roles icon" className={collapsed ? 'panel-navbar-icon-collapse settings-icon' : 'panel-navbar-icon settings-icon'} />
                      {(!collapsed || (collapsed && hovered)) ? <span className="panel-header">{t('menu.personalize')}</span> : null}
                    </div>
                  </Tooltip>
                )
              }
                key="1"
              >
                <Menu mode="inline" selectedKeys={[currentKey]}>
                  {items.filter((item) => ['5'].includes(item.key)).map((item) => (
                    item.canAccess() && (
                      <Menu.Item key={item.key} icon={item.icon}>
                        <Link className={(collapsed && !hovered) ? '' : 'panel-link-title'} to={item.link}>{item.label}</Link>
                      </Menu.Item>
                    )
                  ))}
                </Menu>
              </Panel>
            </Collapse>
          ) : null }
          <div className="footer-menu">
            <button type="button" className="logout-button-candidate-navbar" onClick={showConfirmation}>
              <img src={LogoutIcon} className="logout-icon" alt="logout" />
              {(collapsed && !hovered) ? null : <span>{t('auth.logout')}</span>}
            </button>
          </div>
        </Sider>
      </Layout>
      <ConfirmAction open={open} setOpen={setOpen} onConfirm={handleLogout} />
    </div>
  );
}

export default Navbar;
