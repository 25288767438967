import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Table, Input, notification,
} from 'antd';
import axios from '../axios';
import CreateUsers from '../components/CreateUsers';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmDelete from '../components/ConfirmDelete';
import DeleteIcon from '../images/delete_icon.svg';
import EditIcon from '../images/edit_icon.svg';
import languageStore from '../stores/languageStore';
import '../css/users.css';

import { AbilityContext } from '../Abilities/Can';

function Users() {
  const [t] = useTranslation('global');
  const ability = useContext(AbilityContext);
  const [user, setUser] = useState('');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const navigate = useNavigate();
  const handleRowClick = (record) => {
    const userId = record;
    navigate(`/user/${userId}`);
  };

  const handleEditClick = (record) => {
    const userId = record;
    navigate(`/user/${userId}/edit`);
  };

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
      showSizeChanger: true,
      pageSizeOptions: ['3', '5', '8', '10', '15', '20', '30', '50'],
    },
  });

  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');

  const fetchUsers = async () => {
    setLoading(true);
    axios.get('/api/user')
      .then((response) => {
        setOriginalData(response.data);
        setFilteredData(response.data);
        setLoading(false);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: response.data.length,
          },
        });
      })
      .catch((error) => {
        console.error('Error fetching Users:', error);
        setLoading(false);
      });
  };

  const [hoveredRowKey, setHoveredRowKey] = useState(null);

  // Confirm dialog delete
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userNameToDelete, setUserNameToDelete] = useState('');

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  // Display delete confirmation
  const showDeleteConfirm = (userId, userName) => {
    setUserIdToDelete(userId);
    setUserNameToDelete(userName);
    setShowConfirmation(true);
  };

  // Delete user
  const deleteRow = async () => {
    if (userIdToDelete) {
      try {
        await axios.delete(`/api/user/${userIdToDelete}`);
        // Update Table
        fetchUsers();
        openNotificationWithIcon('success', userNameToDelete + t('confirmModal.success_delete'));
      } catch (error) {
        console.error('Error deleting user:', error);
        openNotificationWithIcon('error', t('confirmModal.error_delete') + userNameToDelete);
      }
    }
    setUserIdToDelete(null);
    setUserNameToDelete('');
  };

  const columns = [
    {
      title: t('profile.name'),
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: '25%',
    },
    {
      title: t('profile.department'),
      dataIndex: ['Department', 'name'],
      key: 'department',
      sorter: (a, b) => a.Department.name.localeCompare(b.Department.name),
      width: '25%',
      render: (text, record) => record.Department.name[selectedLanguage],
    },
    {
      title: t('profile.email'),
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => a.email.localeCompare(b.email),
      width: '30%',
    },
    {
      title: t('users.roles'),
      dataIndex: ['Role', 'name'],
      key: 'roleID',
      render: (text, record) => record.Role.name[selectedLanguage],
      width: '20%',
    },
  ];

  if (ability.can('delete', 'Users') || ability.can('update', 'Users')) {
    // Only display column with edit and delete icon if the user has permissions
    columns.push({
      title: '',
      key: 'actions',
      width: '65px',
      className: 'icons-column',
      render: (text, record) => (
        <Row>
          {/* Edit icon to open User Profile page */}
          {ability.can('update', 'Users') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(record.id);
              }}
            >
              <img src={EditIcon} alt="Edit" />
            </button>
          )}
          {/* Bin icon to delete row */}
          {ability.can('delete', 'Users') /* User must have delete permissions */
          && (user.id !== record.id) /* User can't delete himself */
          && (record.roleID !== 1 || user.roleID === 1) /* Only Admin can delete another Admin */
          && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id, record.name);
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </Row>
      ),
    });
  }

  const locale = {
    emptyText: t('users.empty_list'),
    triggerDesc: t('table.sort_descend'),
    triggerAsc: t('table.sort_ascend'),
    cancelSort: t('table.cancel_sort'),
  };

  useEffect(() => {
    fetchUsers();

    // Get the loggedIn user id and role to display deleting permissions accordingly
    axios.get('/api/user/profile')
      .then((response) => {
        if (response.status === 200) {
          setUser(response.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        ...pagination,
      },
      filters,
      sorter,
    });
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchText(value);
    const filtered = originalData.filter((item) => item.name.toLowerCase().includes(value)
    || item.email.toLowerCase().includes(value));
    setFilteredData(filtered);
  };

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  return (
    <>
      <Navbar />
      <NavbarMobile />
      <div className="users-container">
        <h1 className="users-title">{t('users.title')}</h1>
        <div className="search-and-table-container">
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Input
                placeholder={t('users.search_user')}
                className="search-user search-user-desktop search-user-mobile"
                value={searchText}
                onChange={handleSearch}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} className="users-table-container">
            <Table
              locale={locale}
              onRow={(record) => ({
                onClick: () => handleRowClick(record.id),
                onMouseEnter: () => setHoveredRowKey(record.id),
                onMouseLeave: () => setHoveredRowKey(null),
              })}
              columns={columns}
              dataSource={filteredData}
              className="users-table"
              pagination={tableParams.pagination}
              loading={loading}
              onChange={handleTableChange}
              scroll={{
                x: 500, // Ajust as needed
              }}
            />
          </Col>
          <button type="button" className="new-user-btn" onClick={showModal}>
            {t('users.create_user')}
            <i className="plus-icon">+</i>
          </button>
        </Row>
        <ConfirmDelete
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onConfirm={deleteRow} // Pass the confirmation handler function
          name={userNameToDelete}
        />
        <CreateUsers
          open={open}
          setOpen={setOpen}
          fetchUsers={fetchUsers}
        />
      </div>
    </>
  );
}

export default Users;
