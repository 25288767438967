import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import ChangePasswordForm from '../components/authentication/ModifyPasswordForm';
import Footer from '../components/Footer';
import '../css/App.css';
import RecoverPasswordHeader from '../components/RecoverPasswordHeader';
import axios from '../axios';

function ModifyPassword() {
  const [t] = useTranslation('global');
  const [lightLogo, setLightLogo] = useState('');
  const companyID = 1;

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
      });
  }, []);

  return (
    <div>
      <div className="login">
        <Row>
          <Col xs={24} sm={24} md={9} className="logo-column-recover-password">
            <RecoverPasswordHeader />
            <h1 className="header-title">{t('auth.welcome')}</h1>
            <img src={lightLogo} alt="logo" className="logo-recover-password" id="logo-white" />
          </Col>
          <Col xs={24} sm={24} md={15}>
            <ChangePasswordForm />
          </Col>
        </Row>
      </div>
      <div className="footer-auth">
        <Footer />
      </div>
    </div>
  );
}

export default ModifyPassword;
