import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, DatePicker, ConfigProvider, Checkbox, notification, Select, Table, Row,
} from 'antd';
import { IoIosArrowBack } from 'react-icons/io';
import { AiOutlinePlus } from 'react-icons/ai';
import PropTypes from 'prop-types';
import ptBR from 'antd/locale/pt_BR';
import enUS from 'antd/locale/en_US';
import axios from '../axios';
import ConfirmAction from './ConfirmAction';
import ConfirmDelete from './ConfirmDelete';
import DeleteIcon from '../images/delete_icon.svg';
import EditIcon from '../images/edit_icon.svg';
import languageStore from '../stores/languageStore';
import { AbilityContext } from '../Abilities/Can';
import '../css/createPhase.css';

const { Option } = Select;
const { RangePicker } = DatePicker;

function CreatePhase({
  phasesData, setPhasesData, currentPage, setCurrentPage, seasonStartDate, seasonEndDate,
}) {
  const [t] = useTranslation('global');
  const ability = useContext(AbilityContext);

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [beginDate, setBeginDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [duration, setDuration] = useState('');
  const [phoneInterviewCandidatesAvailability, setPhoneInterviewCandidatesAvailability] = useState('');
  const [groupDynamicCandidates, setGroupDynamicCandidates] = useState('');
  const [groupDynamicInterviewers, setGroupDynamicInterviewers] = useState('');
  const [groupDynamicPresentMembers, setGroupDynamicPresentMembers] = useState([]);
  const [
    phaseTechnicalProblemFileSubmission,
    setPhaseTechnicalProblemFileSubmission,
  ] = useState(false);
  const [phaseTechnicalProblemFormat, setPhaseTechnicalProblemFormat] = useState([]);
  const [phaseInPersonInterviewCandidates, setPhaseInPersonInterviewCandidates] = useState('');
  const [phaseInPersonInterviewInterviewers, setPhaseInPersonInterviewInterviewers] = useState('');
  const [phaseInPersonInterviewPresentMembers, setPhaseInPersonInterviewPresentMembers] = useState(
    [],
  );
  const [open, setOpen] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hoveredRowKey, setHoveredRowKey] = useState(null);
  const [phaseIdToDelete, setPhaseIdToDelete] = useState(null);
  const [phaseNameToDelete, setPhaseNameToDelete] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [phaseIdToEdit, setPhaseIdToEdit] = useState(null);

  const [departmentOptions, setDepartmentOptions] = useState([]);

  /* eslint-disable */
  const [clearImagePreview, setClearImagePreview] = useState(false);

  const datePickerLanguage = selectedLanguage === 'en' ? enUS : ptBR;

  const defaultPhases = [
    {"pt": "Entrevista Telefónica", "en": "Phone Interview"},
    {"pt": "Dinâmica de Grupo", "en": "Group Dynamic"},
    {"pt": "Problema Técnico", "en": "Technical Problem"},
    {"pt": "Entrevista Presencial", "en": "In-Person Interview"}
  ];

  const transformedPhasesData = phasesData.map(phase => ({
    id: phase.id,
    type: phase.type,
    displayType: phase.type[selectedLanguage],
    description: phase.description,
    phase: phase.name,
    start: phase.beginDate,
    end: phase.endDate,
    duration: phase.duration ? `${phase.duration} ${t('season.minutes')}` : t('season.undefined'),
    phoneInterviewCandidatesAvailability: phase.phoneInterviewCandidatesAvailability,
    groupDynamicCandidates: phase.groupDynamicCandidates,
    groupDynamicInterviewers: phase.groupDynamicInterviewers,
    groupDynamicPresentMembers: phase.groupDynamicPresentMembers,
    phaseTechnicalProblemFileSubmission: phase.phaseTechnicalProblemFileSubmission,
    phaseTechnicalProblemFormat: phase.phaseTechnicalProblemFormat,
    phaseInPersonInterviewCandidates: phase.phaseInPersonInterviewCandidates,
    phaseInPersonInterviewInterviewers: phase.phaseInPersonInterviewInterviewers,
    phaseInPersonInterviewPresentMembers: phase.phaseInPersonInterviewPresentMembers
  }));

  const columns = [
    {
      title: t('season.phase'),
      dataIndex: 'phase',
      key: 'phase',
      sorter: (a, b) => a.phase.localeCompare(b.phase),
      width: '25%',
    },
    {
      title: t('season.type'),
      dataIndex: 'displayType',
      key: 'displayType',
      sorter: (a, b) => a.displayType.localeCompare(b.displayType),
      width: '25%',
    },
    {
      title: t('season.start'),
      dataIndex: 'start',
      key: 'start',
      render: (date) => date ? date.format('DD-MM-YYYY') : '',
      width: '15%',
    },
    {
      title: t('season.end'),
      dataIndex: 'end',
      key: 'end',
      render: (date) => date ? date.format('DD-MM-YYYY') : '',
      width: '15%',
    },
    {
      title: t('season.duration'),
      dataIndex: 'duration',
      key: 'duration',
      sorter: (a, b) => a.duration.localeCompare(b.duration),
      width: '25%',
    },
  ];

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const fetchDepartments = async () => {
    try {
      const response = await axios.get('/api/department');
      if (response.status === 200) {
        const departments = response.data;
        const deptOptions = departments.map((element) => ({
          value: element.id,
          label: element.name,
        }));
        setDepartmentOptions(deptOptions);
      }
    } catch (error) {
      console.error('Error during departments fetch:', error);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const onImageUpload = (imageDataURL) => {
    // Do something with the image Data
    console.log(imageDataURL);
  };

  const handleSelectChange = (value) => {
    try {
      const json = JSON.parse(value);
      setType(json);
    } catch (e) {
      setType(value); // or handle the error as needed
    }
  };

  // Function to add a new phase to phasesData
  const addPhase = () => {
    // check if all mandatory fields are filled
    if (!name || !description || !beginDate || !endDate) {
      openNotificationWithIcon('error', t('season.fill_all_fields'));
      return;
    }
    // Validate phase dates
    if (beginDate.isBefore(seasonStartDate) || endDate.isAfter(seasonEndDate)) {
      openNotificationWithIcon('error', t('season.invalid_phase_dates'));
      return;
    }
    const newPhase = {
      id: phasesData.length + 1,
      name,
      type,
      description,
      beginDate,
      endDate,
      duration: duration === t('season.undefined') ? null : duration,
      phoneInterviewCandidatesAvailability,
      groupDynamicCandidates,
      groupDynamicInterviewers,
      groupDynamicPresentMembers,
      phaseTechnicalProblemFileSubmission,
      phaseTechnicalProblemFormat,
      phaseInPersonInterviewCandidates,
      phaseInPersonInterviewInterviewers,
      phaseInPersonInterviewPresentMembers,
      current: false,
    };
    // Update phasesData with the new phase
    let updatedPhasesData;
    if (isEditing) {
      updatedPhasesData = phasesData.map((phase) =>
        phase.id === phaseIdToEdit ? newPhase : phase
      );
    } else {
      updatedPhasesData = [...phasesData, newPhase];
    }
    setPhasesData(updatedPhasesData);

    setCurrentPage(currentPage + 1);

    // Clear the form fields
    setName('');
    setType('');
    setDescription('');
    setBeginDate(null);
    setEndDate(null);
    setDuration('');
    setPhoneInterviewCandidatesAvailability('');
    setGroupDynamicCandidates('');
    setGroupDynamicInterviewers('');
    setGroupDynamicPresentMembers([]);
    setPhaseTechnicalProblemFileSubmission(false);
    setPhaseTechnicalProblemFormat([]);
    setPhaseInPersonInterviewCandidates('');
    setPhaseInPersonInterviewInterviewers('');
    setPhaseInPersonInterviewPresentMembers([]);
    setIsEditing(false);
    setPhaseIdToEdit(null);

    if (isEditing) {
      openNotificationWithIcon('success', t('season.phase_edited'));
    } else {
      openNotificationWithIcon('success', t('season.phase_added'));
    }
  };

  const handleContinue = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage + 1);
  };

  const handleBack = (event) => {
    event.preventDefault();
    setCurrentPage(currentPage - 1);
  };

  const handleCheckboxGroupDynamicMembers = (member, checked) => {
    const updatedMembers = checked
      ? [...groupDynamicPresentMembers, member]
      : groupDynamicPresentMembers.filter((val) => val !== member);
    setGroupDynamicPresentMembers(updatedMembers);
  };

  const handleCheckboxTechnicalProblemFormat = (format, checked) => {
    const updatedFormats = checked
      ? [...phaseTechnicalProblemFormat, format]
      : phaseTechnicalProblemFormat.filter((val) => val !== format);
    setPhaseTechnicalProblemFormat(updatedFormats);
  };

  const handleCheckboxInPersonInterviewMembers = (member, checked) => {
    const updatedMembers = checked
      ? [...phaseInPersonInterviewPresentMembers, member]
      : phaseInPersonInterviewPresentMembers.filter((val) => val !== member);
    setPhaseInPersonInterviewPresentMembers(updatedMembers);
  };

  const showModal = () => {
    setOpen(true);
  }

  const locale = {
    emptyText: t('season.empty_list'),
    triggerDesc: t('table.sort_descend'),
    triggerAsc: t('table.sort_ascend'),
    cancelSort: t('table.cancel_sort'),
  };

  useEffect(() => {
    // update table data
  } , [phasesData]);

  if (ability.can('delete', 'Seasons') || ability.can('update', 'Seasons')) {
    columns.push({
      title: '',
      key: 'actions',
      width: '65px',
      className: 'icons-column',
      render: (text, record) => (
        <Row>
          {ability.can('update', 'Seasons') && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                handleEditClick(record);
              }}
            >
              <img src={EditIcon} alt="Edit" />
            </button>
          )}
          {ability.can('delete', 'Seasons') 
          && (
            <button
              type="button"
              className={hoveredRowKey === record.id ? 'table-icon show' : 'table-icon'}
              onClick={(e) => {
                e.stopPropagation();
                showDeleteConfirm(record.id, record.name);
              }}
            >
              <img src={DeleteIcon} alt="Delete" />
            </button>
          )}
        </Row>
      ),
    });
  }

  const handleEditClick = (record) => {
    setIsEditing(true);
    setPhaseIdToEdit(record.id);
    setName(record.phase);
    setBeginDate(record.start);
    setEndDate(record.end);
    const durationValue = parseInt(record.duration.replace(/\D/g, ''), 10);
    setDuration(durationValue);
    setType(record.type);
    setDescription(record.description);
    handleSelectChange(record.type);
    if (record.type[selectedLanguage] === t('season.phase_phone_interview')) {
      setPhoneInterviewCandidatesAvailability(record.phoneInterviewCandidatesAvailability);
    }
    if (record.type[selectedLanguage] === t('season.phase_group_dynamic')) {
      setGroupDynamicCandidates(record.groupDynamicCandidates);
      setGroupDynamicInterviewers(record.groupDynamicInterviewers);
      setGroupDynamicPresentMembers(record.groupDynamicPresentMembers);
    }
    if (record.type[selectedLanguage] === t('season.phase_technical_problem')) {
      setPhaseTechnicalProblemFileSubmission(record.phaseTechnicalProblemFileSubmission);
      setPhaseTechnicalProblemFormat(record.phaseTechnicalProblemFormat);
    }
    if (record.type[selectedLanguage] === t('season.phase_in_person_interview')) {
      setPhaseInPersonInterviewCandidates(record.phaseInPersonInterviewCandidates);
      setPhaseInPersonInterviewInterviewers(record.phaseInPersonInterviewInterviewers);
      setPhaseInPersonInterviewPresentMembers(record.phaseInPersonInterviewPresentMembers);
      console.log(record.phaseInPersonInterviewPresentMembers);
    }
  };

  const handleCancelEdit = () => {
    setName('');
    setBeginDate(null);
    setEndDate(null);
    setDuration('');
    setType('');
    setDescription('');
    setIsEditing(false);
  };

  const showDeleteConfirm = (phaseId, phaseName) => {
    setPhaseIdToDelete(phaseId);
    setPhaseNameToDelete(phaseName);
    setShowConfirmation(true);
  };

  const deleteRow = () => {
    const updatedPhasesData = phasesData.filter((phase) => phase.id !== phaseIdToDelete);
    setPhasesData(updatedPhasesData);
    setName('');
    setBeginDate(null);
    setEndDate(null);
    setDuration('');
    setType('');
    setDescription('');
    setGroupDynamicCandidates('');
    setGroupDynamicInterviewers('');
    setGroupDynamicPresentMembers([]);
    setPhaseTechnicalProblemFileSubmission(false);
    setPhaseTechnicalProblemFormat([]);
    setPhaseInPersonInterviewCandidates('');
    setPhaseInPersonInterviewInterviewers('');
    setPhaseInPersonInterviewPresentMembers([]);
    setIsEditing(false);
    setShowConfirmation(false);
    openNotificationWithIcon('success', t('season.phase_deleted'));
  }

  useEffect(() => {
  }
  , [type]);

  const handleCheckboxChange = (setter) => (e) => {
    setter(e.target.checked);
  };

  return (
    <div>
      <div className="create-phase-header">
        <button onClick={handleBack} className="go-back-btn">
          <IoIosArrowBack />
        </button>
        <h1 className="create-phase-title">{t('season.phase_title')}</h1>
      </div>
      <div className="create-phase-name-div">
        <p className="create-phase-name-label">
          {t('season.name')}
          :
        </p>
        <Input
          className="create-phase-name-input"
          type="text"
          id="name"
          name="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </div>
      <div className="create-phase-description-div">
        <p className="create-phase-description-label">
          {t('season.phase_description_label')}
        </p>
        <Input.TextArea
          className="create-phase-description-input"
          type="text"
          id="description"
          name="description"
          value={description}
          placeholder={t('season.phase_description_label_placeholder')}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>
      <div className="create-phase-begin-date-div">
        <p className="create-phase-begin-date-label">
          {t('season.date')}
          :
        </p>
         <ConfigProvider locale={datePickerLanguage}>
          <RangePicker
            className="create-phase-begin-date-input"
            value={[beginDate, endDate]}
            placeholder={[t('season.start_date'), t('season.end_date')]}
            disabledDate={(current) => current && (current.isBefore(seasonStartDate, 'day') || current.isAfter(seasonEndDate, 'day'))}
            onChange={(dates) => {
              const [start, end] = dates;
              setBeginDate(start);
              setEndDate(end);
            }}
          />
        </ConfigProvider>
      </div>
      <div className="create-phase-select-phases-types">
        <Select
          placeholder={t('season.phase_select')}
          onChange={handleSelectChange}
          value={type ? type[selectedLanguage] : undefined}
          className="create-phase-select-phases-input"
        >
          {defaultPhases.map((phase, index) => (
            <Option key={index} value={JSON.stringify(phase)}>
              {phase[selectedLanguage]}
            </Option>
          ))}
        </Select>
      </div>
      {type[selectedLanguage] === t('season.phase_phone_interview') && (
        <div className="phase-phone-interview-div">
          <p className="phase-phone-interview-candidates-availability">
            {t('season.phase_phone_interview_candidates_availability')}
            <span className="submit-button">
              <Checkbox checked={phoneInterviewCandidatesAvailability} onChange={handleCheckboxChange(setPhoneInterviewCandidatesAvailability)}></Checkbox>
            </span>
          </p>
        </div>
      )}
      {type[selectedLanguage] === t('season.phase_group_dynamic') && (
        <div className="phase-group-dynamic-div">
          <div className="phase-group-dynamic-time-div">
            <p className="phase-group-dynamic-time">
              {t('season.phase_group_dynamic_time')}
              :
            </p>
            <div className="phase-group-dynamic-time-input-div">
              <input
                className="phase-group-dynamic-time-input"
                type="number"
                min="0"
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            <span className="phase-group-dynamic-time-input-inside-label">{t('season.phase_group_dynamic_minutes')}</span>
            </div>
          </div>
          <div className="phase-group-dynamic-n-candidates-div">
            <p className="phase-group-dynamic-n-candidates">
              {t('season.phase_group_dynamic_candidates_number')}
              :
            </p>
            <input
              className="phase-group-dynamic-n-candidates-input"
              type="number"
              min="0"
              value={groupDynamicCandidates}
              onChange={(e) => setGroupDynamicCandidates(e.target.value)}
            />
          </div>
          <div className="phase-group-dynamic-n-interviewers-div">
            <p className="phase-group-dynamic-n-interviewers">
              {t('season.phase_group_dynamic_interviewers_number')}
              :
            </p>
            <input
              className="phase-group-dynamic-n-interviewers-input"
              type="number"
              min="0"
              value={groupDynamicInterviewers}
              onChange={(e) => setGroupDynamicInterviewers(e.target.value)}
            />
          </div>
          <div className="phase-group-dynamic-present-members-div">
            <p className="phase-group-dynamic-present-members">
              {t('season.phase_group_dynamic_present_members')}
              :
            </p>
            <div className="phase-group-dynamic-present-members-checkboxes">
              <Checkbox checked={groupDynamicPresentMembers.includes("directors")} onChange={(e) => handleCheckboxGroupDynamicMembers("directors", e.target.checked)}>{t('season.phase_group_dynamic_present_members_directors')}</Checkbox>
              <Checkbox checked={groupDynamicPresentMembers.includes("membersDiversity")} onChange={(e) => handleCheckboxGroupDynamicMembers("membersDiversity", e.target.checked)}>{t('season.phase_group_dynamic_present_members_diversity')}</Checkbox>
            </div>
          </div>
        </div>
      )}
      {type[selectedLanguage] === t('season.phase_technical_problem') && (
        <div className="phase-technical-problem-div">
          <div className="phase-technical-problem-submit-div">
            <p className="phase-technical-problem-submit">
              {t('season.phase_technical_problem_file_submission')}
              <span className="submit-button">
                <Checkbox checked={phaseTechnicalProblemFileSubmission} onChange={handleCheckboxChange(setPhaseTechnicalProblemFileSubmission)}></Checkbox>
              </span>
            </p>
          </div>
          {phaseTechnicalProblemFileSubmission && (
            <>
              <div className="phase-technical-problem-format-div">
                <p className="phase-technical-problem-format">
                  {t('season.phase_technical_problem_file_format')}
                  :
                </p>
                <div className="phase-technical-problem-format-checkboxes">
                  <Checkbox checked={phaseTechnicalProblemFormat.includes("pdf")} onChange={(e) => handleCheckboxTechnicalProblemFormat("pdf", e.target.checked)}>PDF</Checkbox>
                  <Checkbox checked={phaseTechnicalProblemFormat.includes("image")} onChange={(e) => handleCheckboxTechnicalProblemFormat("image", e.target.checked)}>{t('applicationForm.image')}</Checkbox>
                  <Checkbox checked={phaseTechnicalProblemFormat.includes("other")} onChange={(e) => handleCheckboxTechnicalProblemFormat("other", e.target.checked)}>{t('applicationForm.all')}</Checkbox>
                </div>
              </div>
            </>
          )}
        </div>
      )}
      {type[selectedLanguage] === t('season.phase_in_person_interview') && (
        <div className="phase-in-person-interview-div">
        <div className="phase-in-person-interview-time-div">
          <p className="phase-in-person-interview-time">
            {t('season.phase_in_person_interview_time')}
            :
          </p>
          <div className="phase-in-person-interview-time-input-div">
            <input
              className="phase-in-person-interview-time-input"
              type="number"
              min="0"
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            />
          <span className="phase-in-person-interview-time-input-inside-label">{t('season.phase_group_dynamic_minutes')}</span>
          </div>
        </div>
        <div className="phase-in-person-interview-n-candidates-div">
          <p className="phase-in-person-interview-n-candidates">
            {t('season.phase_group_dynamic_candidates_number')}
            :
          </p>
          <input
            className="phase-in-person-interview-n-candidates-input"
            type="number"
            min="0"
            value={phaseInPersonInterviewCandidates}
            onChange={(e) => setPhaseInPersonInterviewCandidates(e.target.value)}
          />
        </div>
        <div className="phase-in-person-interview-n-interviewers-div">
          <p className="phase-in-person-interview-n-interviewers">
            {t('season.phase_group_dynamic_interviewers_number')}
            :
          </p>
          <input
            className="phase-in-person-interview-n-interviewers-input"
            type="number"
            min="0"
            value={phaseInPersonInterviewInterviewers}
            onChange={(e) => setPhaseInPersonInterviewInterviewers(e.target.value)}
          />
        </div>
        <div className="phase-in-person-interview-present-members-div">
          <p className="phase-in-person-interview-present-members">
            {t('season.phase_group_dynamic_present_members')}
            :
          </p>
          <div className="phase-in-person-interview-present-members-checkboxes">
            <Checkbox checked={phaseInPersonInterviewPresentMembers.includes("directors")} onChange={(e) => handleCheckboxInPersonInterviewMembers("directors", e.target.checked)}>{t('season.phase_group_dynamic_present_members_directors')}</Checkbox>
            {departmentOptions.map((department) => (
              <Checkbox checked={phaseInPersonInterviewPresentMembers.includes(department.label)} onChange={(e) => handleCheckboxInPersonInterviewMembers(department.label, e.target.checked)}>{department.label[selectedLanguage]}</Checkbox>
            ))}
          </div>
        </div>
      </div>
      )}
      {isEditing ? (
        <div className="edit-phase-buttons">
          <button type="button" onClick={showModal} className="edit-phase-btn">
            {t('season.edit_phase')}
            <AiOutlinePlus />
          </button>
          <button type="button" onClick={handleCancelEdit} className="cancel-edit-phase-btn">
            {t('season.cancel')}
          </button>
          </div>
      ) : (
        <button type="button" onClick={showModal} className="add-phase-btn">
          {t('season.add_phase')}
          <AiOutlinePlus />
        </button>
      )}
      <Table
        locale={locale}
        onRow={(record) => ({
          onMouseEnter: () => setHoveredRowKey(record.id),
          onMouseLeave: () => setHoveredRowKey(null),
        })}
        columns={columns}
        dataSource={transformedPhasesData}
        pagination={phasesData.length > 10}
        scroll={{
          x: 500, // Ajust as needed
        }}
      />
      <ConfirmAction
        open={open}
        setOpen={setOpen}
        onConfirm={addPhase}
      />
      <ConfirmDelete
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={deleteRow} 
        name={phaseNameToDelete}
      />
    </div>
  );
}

CreatePhase.propTypes = {
  phasesData: PropTypes.array.isRequired,
  setPhasesData: PropTypes.func.isRequired,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  seasonStartDate: PropTypes.object.isRequired,
  seasonEndDate: PropTypes.object.isRequired,
};

export default CreatePhase;
