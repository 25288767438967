import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Input, notification, Checkbox } from 'antd';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import { AbilityContext } from '../../Abilities/Can';
import axios from '../../axios';
import GoogleLogo from '../../images/google_icon.svg';
import '../../css/App.css';
import ExternalAuthButton from './ExternalAuthButton';
import { useAuth } from '../../contexts/AuthContext';
import TranslateButton from '../TranslateButton';

function LoginForm() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const ability = useContext(AbilityContext);
  const [t] = useTranslation('global');
  const { login } = useAuth();
  const [rememberMe, setRememberMe] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('auth.success'),
        description: t('auth.login_message') + (message ? `: ${message}` : ''),
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('auth.error'),
        description: message,
      });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        '/api/user/login',
        { email, password, rememberMe },
      );
      if (response.status === 200) {
        const responseData = response.data;

        if (responseData.type === 'user') {
          axios.get(`/api/user/permission/${response.data.id}`).then((permissionResponse) => {
            const { can, rules } = new AbilityBuilder(createMongoAbility);
            permissionResponse.data.forEach((permission) => {
              can(permission.action, permission.subject);
            });
            ability.update(rules);
          }).then(() => {
            login(responseData.permissions);
            openNotificationWithIcon('success');
            if (ability.can('update', 'Users')) {
              navigate(`/user/${response.data.id}`);
            } else {
              navigate('/profile');
            }
          });
        } else if (responseData.type === 'candidate') {
          login([]);
          openNotificationWithIcon('success');
          navigate(`/candidate/${response.data.id}`);
        } else {
          openNotificationWithIcon('error', 'An error occurred during login');
        }
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        if (status === 400) {
          openNotificationWithIcon('error', t('auth.login_400'));
        } else if (status === 404) {
          openNotificationWithIcon('error', t('auth.login_404'));
        } else if (status === 401) {
          openNotificationWithIcon('error', t('auth.login_401'));
        } else if (status === 402) {
          openNotificationWithIcon('error', t('auth.login_402'));
        } else {
          openNotificationWithIcon('error', error.response.data.message);
        }
      } else {
        openNotificationWithIcon('error', t('auth.login_error'));
      }
    }
  };

  const handleChange = (e) => {
    setRememberMe(e.target.checked);
  };
  return (
    <div className="auth-container">
      <h1 className="auth-title">
        {t('auth.login')}
        <TranslateButton />
      </h1>
      <form className="input-container" onSubmit={handleLogin}>
        <Input
          className="input-field"
          placeholder={t('auth.email')}
          bordered={false}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Input.Password
          id="password-input"
          className="input-field"
          placeholder={t('auth.password')}
          bordered={false}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Checkbox value={rememberMe} onChange={handleChange}><span className="keep-logged-text">{t('auth.remember_me')}</span></Checkbox>
        <p className="additional-text"><a className="auth-link" href="/recover-password">{t('auth.forgot_password')}</a></p>
        <button className="auth-button" type="submit">{t('auth.login')}</button>
      </form>
      <ExternalAuthButton linkProp={`${process.env.REACT_APP_API_BASE_URL}/api/user/auth/google`} iconProp={GoogleLogo} labelProp={t('auth.continue_with_google')} />
    </div>
  );
}

export default LoginForm;
