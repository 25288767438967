import React, { useState, useEffect } from 'react';
import {
  Col, Row, notification, Button,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import languageStore from '../stores/languageStore';

function Role() {
  const [t] = useTranslation('global');
  const navigate = useNavigate();

  let roleID = 0;
  const companyID = 1;
  const { id } = useParams();
  roleID = id;
  const editRoleURL = `/role/${roleID}/edit`;

  const [roleName, setRoleName] = useState('');
  const [roleDescription, setRoleDescription] = useState('');
  const [rolePermissions, setRolePermissions] = useState([]);
  const [permissionSubjects, setPermissionSubjects] = useState([]);
  const [darkLogo, setDarkLogo] = useState('');
  const [LightLogo, setLightLogo] = useState('');

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(
      `api/role/${roleID}`,
    )
      .then((response) => {
        setRoleName(response.data.name[selectedLanguage]);
        setRolePermissions(response.data.permissions);
        setRoleDescription(response.data.description[selectedLanguage]);
      })
      .catch(() => {
        openNotificationWithIcon('error', 'Error fetching role');
      });
  }, [languageStore.getState().currentLanguage]);
  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, [languageStore.getState().currentLanguage]);

  useEffect(() => {
    axios.get('/api/role/permission')
      .then((response) => {
        const filteredPermissions = response.data;
        const subjects = filteredPermissions.map((permission) => permission.subject);
        setPermissionSubjects([...new Set(subjects)]);
        // setPermissions(filteredPermissions);
      })
      .catch((error) => {
        console.error('Error fetching Permissions:', error);
      });
  }, [languageStore.getState().currentLanguage]);

  return (
    <div>
      <Row className="role-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={9} className="roles-page-col1">
          <img src={LightLogo} alt="logo" className="roles-white-logo" />
        </Col>
        <Col xs={24} sm={24} md={15} className="role-page-col2">
          <img src={darkLogo} alt="logo" className="profile-logo desktop" id="logo-desktop" />
          <div className="role-page-col2-content">
            <div className="role-info">
              <h1 className="role-page-title-desktop">{t('roles.title')}</h1>
              <div className="role-page-container">
                <h3 className="role-info-name-title">{t('role.name')}</h3>
                <p className="role-info-name">{roleName}</p>
                <h3 className="role-info-name-title">{t('role.description')}</h3>
                <p className="role-info-name">{roleDescription}</p>
                <div className="role-page-permissions">
                  <h3 className="role-info-permission-title">{t('roles.permissions')}</h3>
                  {permissionSubjects
                    .filter((subject) => rolePermissions
                      .some((permission) => permission.subject === subject))
                    .map((subject) => (
                      <div className="role-info-permissions" key={subject}>
                        <p className="role-info-permission-user">{t(`role.${subject}`)}</p>
                        <div className="role-permission-grid">
                          {/* Filter permissions by subject */}
                          {rolePermissions
                            .filter((permission) => permission.subject === subject && permission.action !== 'manage')
                            .map((filteredPermission) => (
                              <div className="role-permission-item" key={filteredPermission.id}>
                                <p className="role-info-permission-text">
                                  {t(`role.${filteredPermission.action}`)}
                                </p>
                              </div>
                            ))}
                          {/* If manage permission is present, replace it with crud */}
                          {rolePermissions.some((permission) => permission.subject === subject && permission.action === 'manage') && (
                            <div className="role-permission-item">
                              <p className="role-info-permission-text">
                                {t('roles.create')}
                                {' '}
                                {t(`roles.${subject}`)}
                              </p>
                            </div>
                          )}
                          {rolePermissions.some((permission) => permission.subject === subject && permission.action === 'manage') && (
                            <div className="role-permission-item">
                              <p className="role-info-permission-text">
                                {t('roles.read')}
                                {' '}
                                {t(`roles.${subject}`)}
                              </p>
                            </div>
                          )}
                          {rolePermissions.some((permission) => permission.subject === subject && permission.action === 'manage') && (
                            <div className="role-permission-item">
                              <p className="role-info-permission-text">
                                {t('roles.update')}
                                {' '}
                                {t(`roles.${subject}`)}
                              </p>
                            </div>
                          )}
                          {rolePermissions.some((permission) => permission.subject === subject && permission.action === 'manage') && (
                            <div className="role-permission-item">
                              <p className="role-info-permission-text">
                                {t('roles.delete')}
                                {' '}
                                {t(`roles.${subject}`)}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <Button className="edit-role-button" htmlType="submit" type="submit" onClick={() => navigate(editRoleURL)}>
              {t('roles.edit')}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Role;
