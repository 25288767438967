import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Col, Row, notification, Button, Input, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PhoneInput } from 'react-international-phone';
import { UploadOutlined } from '@ant-design/icons';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmAction from '../components/ConfirmAction';
import ApplicationIcon from '../images/application.svg';
import MessageIcon from '../images/message.svg';
import languageStore from '../stores/languageStore';
import '../css/candidateView.css';

function CandidateView() {
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const [userType, setUserType] = useState('');
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [firstOption, setFirstOption] = useState('');
  const [secondOption, setSecondOption] = useState('');
  const [submitionDate, setSubmissionDate] = useState('');
  const [candidateInfo, setCandidateInfo] = useState('');
  const [phases, setPhases] = useState([]);
  const [candidateMessage, setCandidateMessage] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [originalValues, setOriginalValues] = useState({});
  const [originalMessage, setOriginalMessage] = useState({});
  const companyID = 1;
  const [darkLogo, setDarkLogo] = useState('');
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingMessage, setIsEditingMessage] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const editItemsPerPage = 12;
  const itemsPerPage = 14;
  const candidateInfoPerPage = currentPage === 1 ? 8 : itemsPerPage;
  const editCandidateInfoPerPage = currentPage === 1 ? 8 : editItemsPerPage;

  const { candidateID } = useParams();

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  useEffect(() => {
    axios.get('/api/job')
      .then((response) => {
        setJobs(response.data);
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
        openNotificationWithIcon('error', t('job.fetch_failed'));
      });
  }, []);

  // eslint-disable-next-line
  const processCandidateInfo = (candidateInfoData) => {
    return candidateInfoData.map((field, index) => ({
      ...field,
      id: index + 1,
    }));
  };

  const fetchProfile = () => {
    axios.get(
      'api/user/profile',
    )
      .then((response) => {
        setUserType(response.data.type);
        if (response.data.type !== 'candidate') {
          axios.get(`/api/user/candidate/${candidateID}`)
            .then((res) => {
              if (res.data.isValid === false) {
                navigate('/candidates');
              }
              setId(res.data.id);
              setName(res.data.name);
              setEmail(res.data.email);
              setIsValid(res.data.isValid);
              setSubmissionDate(res.data.updatedAt);
              if (res.data.Jobs.length > 0) {
                setFirstOption(res.data.Jobs[0].name[selectedLanguage]);
                if (res.data.Jobs.length > 1) {
                  setSecondOption(res.data.Jobs[1].name[selectedLanguage]);
                }
              }
              if (res.data.candidateInfo) {
                setCandidateInfo(processCandidateInfo(res.data.candidateInfo));
              }
              if (res.data.phases) {
                setPhases(res.data.phases);
              }
              if (res.data.message) {
                setCandidateMessage(res.data.message[0]);
              }
            });
          return;
        }
        setId(response.data.user.id);
        setName(response.data.user.name);
        setEmail(response.data.user.email);
        setIsValid(response.data.user.isValid);
        setSubmissionDate(response.data.user.updatedAt);
        if (response.data.user.Jobs.length > 0) {
          setFirstOption(response.data.user.Jobs[0].name[selectedLanguage]);
          if (response.data.user.Jobs.length > 1) {
            setSecondOption(response.data.user.Jobs[1].name[selectedLanguage]);
          }
        }
        if (response.data.user.candidateInfo) {
          setCandidateInfo(processCandidateInfo(response.data.user.candidateInfo));
        }
        if (response.data.user.phases) {
          setPhases(response.data.user.phases);
        }
        if (response.data.user.message) {
          setCandidateMessage(response.data.user.message[0]);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openNotificationWithIcon('error', t('auth.need_login'));
          navigate('/login');
        } else if (error.response.status === 403) {
          openNotificationWithIcon('error', t('auth.not_authorized'));
          navigate('/login');
        } else if (error.response.status === 404) {
          openNotificationWithIcon('error', t('profile.not_found'));
          navigate('/');
        }
      });
  };

  useEffect(() => {
    fetchProfile();
  }, [selectedLanguage]);

  const startIndex = (currentPage - 1) * itemsPerPage - (currentPage > 1 ? 6 : 0);
  const endIndex = startIndex + candidateInfoPerPage;
  const currentCandidateInfo = candidateInfo.slice(startIndex, endIndex);

  const editStartIndex = (currentPage - 1) * editItemsPerPage - (currentPage > 1 ? 4 : 0);
  const editEndIndex = editStartIndex + editCandidateInfoPerPage;
  const currentEditCandidateInfo = candidateInfo.slice(editStartIndex, editEndIndex);

  const formatDate = (date) => {
    if (!date) {
      return '';
    }
    const newDate = new Date(date);
    const day = String(newDate.getDate()).padStart(2, '0');
    const month = String(newDate.getMonth() + 1).padStart(2, '0');
    const year = newDate.getFullYear();
    return `${day} / ${month} / ${year}`;
  };

  const handleEditClick = () => {
    setOriginalValues({
      firstOption,
      secondOption,
      submitionDate,
      candidateInfo,
    });
    setIsEditing(true);
  };

  const handleEditMessageClick = () => {
    setOriginalMessage(candidateMessage);
    setIsEditingMessage(true);
  };

  const handleCancelClick = () => {
    setFirstOption(originalValues.firstOption);
    setSecondOption(originalValues.secondOption);
    setSubmissionDate(originalValues.submitionDate);
    setCandidateInfo(originalValues.candidateInfo);
    setIsEditing(false);
  };

  const handleCancelMessageClick = () => {
    setOriginalMessage(candidateMessage);
    setIsEditingMessage(false);
  };

  const handleCandidateEdit = async () => {
    try {
      const response = await axios.post(`/api/user/${id}/editCandidate`, {
        name,
        email,
        candidateInfo,
        message: originalMessage.length === 0 ? [originalMessage] : [candidateMessage],
      });
      if (response.status === 200) {
        openNotificationWithIcon('success', t('candidateView.success_edit'));
        fetchProfile();
        setIsEditing(false);
        setIsEditingMessage(false);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 400:
            openNotificationWithIcon('error', t('candidateView.error_edit'));
            break;
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          case 404:
            openNotificationWithIcon('error', t('candidateView.not_found'));
            break;
          default:
            openNotificationWithIcon('error', t('candidateView.error_edit'));
            break;
        }
      }
    }
  };

  const updateFieldValue = (fieldId, newValue, fieldType) => {
    // eslint-disable-next-line
    setCandidateInfo((prevCandidateInfo) => {
      return prevCandidateInfo.map((field) => {
        if (field.id !== fieldId) {
          return field;
        }

        // Create the updated field based on type
        let updatedValue = newValue;
        // Handle type-specific value updates
        switch (fieldType) {
          case 'file':
            // For files, store the File object or filename
            updatedValue = newValue instanceof File ? newValue : field.value;
            break;
          case 'contact':
            // For contact, ensure proper phone format
            updatedValue = newValue || '';
            break;
          case 'date':
            // For dates, ensure proper date format
            updatedValue = newValue || '';
            break;
          case 'number':
            // For numbers, ensure proper number format
            updatedValue = newValue || '';
            break;
          case 'select':
            // For select, store the value
            updatedValue = newValue || '';
            break;
          case 'text':
          default:
            // For text and other types, store as is
            updatedValue = newValue || '';
        }

        return {
          ...field,
          value: updatedValue,
        };
      });
    });
  };

  const handleFileFormat = (formats) => {
    if (formats.includes('other')) {
      return 'file';
    }
    if (formats.includes('pdf') && formats.includes('image')) {
      return 'application/pdf, image/*';
    }
    if (formats.includes('image')) {
      return 'image/*';
    }
    if (formats.includes('pdf')) {
      return 'application/pdf';
    }
    return 'file';
  };

  const handleContactFormat = (value) => {
    if (!value) {
      return '';
    }
    if (value.slice(0, 4) === '+351') {
      return `+351 ${value.slice(4, 7)} ${value.slice(7, 10)} ${value.slice(10, 13)}`;
    }
    return `${value.slice(0, 3)} ${value.slice(3, 6)} ${value.slice(6, 9)}`;
  };

  const renderField = (field) => {
    switch (field.type) {
      case 'text':
        return (
          <Input
            className="candidate-view-input"
            value={field.value || ''}
            placeholder={selectedLanguage === 'pt'
              ? (field.placeholder && field.placeholder.pt) || field.pt
              : (field.placeholder && field.placeholder.en) || field.en}
            onChange={(e) => updateFieldValue(field.id, e.target.value, 'text')}
          />
        );

      case 'long text':
        return (
          <Input.TextArea
            className="candidate-view-input"
            value={field.value || ''}
            placeholder={selectedLanguage === 'pt'
              ? (field.placeholder && field.placeholder.pt) || field.pt
              : (field.placeholder && field.placeholder.en) || field.en}
            onChange={(e) => updateFieldValue(field.id, e.target.value, 'text')}
          />
        );

      case 'file':
        return (
          <div className="candidate-view-input-file-input">
            <input
              type="file"
              accept={handleFileFormat(field.fileFormat)}
              className="inputfile"
              id={`file-input-${field.id}`}
              onChange={async (e) => {
                const file = e.target.files?.[0];
                if (file) {
                  const formData = new FormData();
                  formData.append('file', file);
                  try {
                    const response = await axios.post(`/api/upload/candidateInfo/${id}/${field.id}`, formData, {
                      headers: {
                        'Content-Type': 'multipart/form-data',
                      },
                    });
                    setCandidateInfo(response.data.candidate.candidateInfo);
                    updateFieldValue(field.id, response.data.candidate.candidateInfo.find((f) => f.id === field.id).value, 'file');
                  } catch (error) {
                    console.error('Error uploading file:', error);
                  }
                }
              }}
            />
            <label htmlFor={`file-input-${field.id}`}>
              {field.placeholder && (field.placeholder.pt || field.placeholder.en) ? ( // eslint-disable-line
                selectedLanguage === 'pt' ? field.placeholder.pt : field.placeholder.en
              ) : (
                t('applicationForm.choose_file')
              )}
            </label>
            <UploadOutlined className="upload-icon" />
          </div>
        );

      case 'date':
        return (
          <Input
            className="candidate-view-input"
            type="date"
            value={field.value || ''}
            onChange={(e) => updateFieldValue(field.id, e.target.value, 'date')}
          />
        );

      case 'contact':
        return (
          <PhoneInput
            defaultCountry="pt"
            className="candidate-view-preview-field-phone"
            value={handleContactFormat(field.value) || ''}
            placeholder={selectedLanguage === 'pt'
              ? (field.placeholder && field.placeholder.pt) || field.pt
              : (field.placeholder && field.placeholder.en) || field.en}
            onChange={(value) => updateFieldValue(field.id, value, 'contact')}
          />
        );

      case 'number':
        return (
          <Input
            className="candidate-view-input"
            type="number"
            value={field.value || ''}
            placeholder={selectedLanguage === 'pt'
              ? (field.placeholder && field.placeholder.pt) || field.pt
              : (field.placeholder && field.placeholder.en) || field.en}
            onChange={(e) => updateFieldValue(field.id, e.target.value, 'number')}
          />
        );
      case 'select':
        return (
          <Select
            showSearch
            optionFilterProp="children"
            className="candidate-view-input"
            placeholder={selectedLanguage === 'pt'
              ? (field.placeholder && field.placeholder.pt) || field.pt
              : (field.placeholder && field.placeholder.en) || field.en}
            value={field.value}
            onChange={(value) => updateFieldValue(field.id, value, 'select')}
            options={field.options.map((option) => ({
              label: selectedLanguage === 'pt' ? option.pt : option.en,
              value: option.value[selectedLanguage],
            }))}
          />
        );
      default:
        return null;
    }
  };

  const jobOptions = jobs.map((job) => ({
    value: job.name[selectedLanguage],
    label: job.name[selectedLanguage],
  }));

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleApprove = () => {
    setCurrentAction('approve');
    setShowConfirmation(true);
  };

  const handleReject = () => {
    setCurrentAction('reject');
    setShowConfirmation(true);
  };

  const handleClear = () => {
    setCurrentAction('clear');
    setShowConfirmation(true);
  };

  const handleConfirm = async () => {
    if (currentAction === 'approve') {
      handleApprove();
      await axios.put(`/api/user/candidate/${id}/updatePhase`, {
        status: 'approved',
      });
    } else if (currentAction === 'reject') {
      handleReject();
      await axios.put(`/api/user/candidate/${id}/updatePhase`, {
        status: 'rejected',
      });
    } else if (currentAction === 'clear') {
      handleClear();
      await axios.put(`/api/user/candidate/${id}/updatePhase`, {
        status: 'ongoing',
      });
    }
    setShowConfirmation(false);
    fetchProfile();
  };

  const candidatePhaseInfo = (phase) => {
    switch (phase.status) {
      case 'pending':
        return t('candidateView.not_opened');
      case 'approved':
        return t('candidateView.approved');
      case 'rejected':
        return t('candidateView.rejected');
      case 'excluded':
        return t('candidateView.excluded');
      default:
        return '';
    }
  };

  return (
    <div>
      <Row className="candidate-view-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={24} className="candidate-view-col2">
          <h1 className="candidate-view-title-desktop">{t('candidateView.title')}</h1>
          <img src={darkLogo} alt="logo" className="profile-logo desktop" id="logo-desktop" />
          <Row className="candidate-view-col-row">
            <div className="candidate-view-col-container">
              <Col xs={12} sm={12} md={12} className="candidate-view-col3">
                <Row className="candidate-view-col3-title-row">
                  <img src={ApplicationIcon} alt="Application" className="candidate-view-col3-icon" />
                  <h1 className="candidate-view-col3-title">{t('candidateView.col1_title')}</h1>
                </Row>
                <div className="candidate-view-col3-info-container">
                  {!isEditing ? (
                    <Col xs={24} sm={24} md={24}>
                      {currentPage === 1 && (
                        <>
                          <Row className="candidate-view-col3-info-row">
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">
                                  {t('candidateView.name')}
                                  *
                                </h3>
                                <text>{name}</text>
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">Email*</h3>
                                <text>{email}</text>
                              </div>
                            </Col>
                          </Row>
                          <Row className="candidate-view-col3-info-row">
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">
                                  {t('candidateView.first_option')}
                                  *
                                </h3>
                                <text className={firstOption ? '' : 'not-filled-required'}>{firstOption || t('candidateView.not_filled')}</text>
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">{t('candidateView.second_option')}</h3>
                                <text className={secondOption ? '' : 'not-filled'}>{secondOption || t('candidateView.not_filled')}</text>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row className="candidate-view-col3-info-row">
                        {candidateInfo && currentCandidateInfo.map((info) => (
                          <Col xs={12} sm={12} md={12}>
                            <div className="candidate-view-col3-info">
                              <h3 className="candidate-view-info-field-title">
                                {selectedLanguage === 'pt' ? info.pt : info.en}
                                {info.isRequired ? '*' : ''}
                              </h3>
                              {info.type === 'file' && info.value !== '' && info.value !== null ? (  // eslint-disable-line
                                <a href={info.value}>{selectedLanguage === 'pt' ? info.pt : info.en}</a>
                              ) : info.type === 'contact' && info.value ? (
                                <text className={!info.value ? (info.isRequired ? 'not-filled-required' : 'not-filled') : ''}>{handleContactFormat(info.value)}</text>  // eslint-disable-line
                              ) : (
                                <text className={!info.value ? (info.isRequired ? 'not-filled-required' : 'not-filled') : ''}>{info.value || t('candidateView.not_filled')}</text> // eslint-disable-line
                              )}
                            </div>
                          </Col>
                        ))}
                      </Row>
                      <div className="candidate-view-button-container">
                        <Button className="candidate-view-pagination-button" onClick={handlePreviousPage} disabled={currentPage === 1}>
                          {t('candidateView.previous')}
                        </Button>
                        <Button className="candidate-view-pagination-button" onClick={handleNextPage} disabled={endIndex >= candidateInfo.length}>
                          {t('candidateView.next')}
                        </Button>
                      </div>
                      <Button className="edit-application-button" onClick={handleEditClick}>
                        {t('candidateView.edit')}
                      </Button>
                    </Col>
                  ) : (
                    <Col xs={24} sm={24} md={24}>
                      {currentPage === 1 && (
                        <>
                          <Row className="candidate-view-col3-info-row">
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">{t('candidateView.name')}</h3>
                                <Input
                                  className="candidate-view-input"
                                  value={name}
                                  placeholder={t('candidateView.name')}
                                  onChange={(e) => setName(e.target.value)}
                                />
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">Email</h3>
                                <Input
                                  className="candidate-view-input"
                                  value={email}
                                  placeholder="Email"
                                  onChange={(e) => setEmail(e.target.value)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="candidate-view-col3-info-row">
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">{t('candidateView.first_option')}</h3>
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  className="candidate-view-input"
                                  placeholder={t('candidateView.first_option')}
                                  value={firstOption}
                                  onChange={(value) => setFirstOption(value)}
                                  options={jobOptions}
                                />
                              </div>
                            </Col>
                            <Col xs={12} sm={12} md={12}>
                              <div className="candidate-view-col3-info">
                                <h3 className="candidate-view-info-field-title">{t('candidateView.second_option')}</h3>
                                <Select
                                  showSearch
                                  optionFilterProp="children"
                                  className="candidate-view-input"
                                  placeholder={t('candidateView.second_option')}
                                  value={secondOption}
                                  onChange={(value) => setSecondOption(value)}
                                  options={jobOptions}
                                />
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row className="candidate-view-col3-info-row">
                        {candidateInfo && currentEditCandidateInfo?.map((field, index) => (
                          /* eslint-disable-next-line */
                          <Col key={`${field.type}-${index}-${field.id}`} xs={12} sm={12} md={12}>
                            <div className="candidate-view-col3-info">
                              <h3 className="candidate-view-info-field-title">
                                {selectedLanguage === 'pt' ? field.pt : field.en}
                                {field.isRequired ? '*' : ''}
                              </h3>
                              {renderField(field)}
                            </div>
                          </Col>
                        ))}
                      </Row>
                      <div className="candidate-view-button-container">
                        <Button className="candidate-view-pagination-button" onClick={handlePreviousPage} disabled={currentPage === 1}>
                          {t('candidateView.previous')}
                        </Button>
                        <Button className="candidate-view-pagination-button" onClick={handleNextPage} disabled={endIndex >= candidateInfo.length}>
                          {t('candidateView.next')}
                        </Button>
                      </div>
                      <Row className="candidate-view-col3-button-row">
                        <Button className="save-application-button" onClick={handleCandidateEdit}>
                          {t('candidateView.save')}
                        </Button>
                        <Button className="cancel-edit-button" onClick={(handleCancelClick)}>
                          {t('candidateView.cancel')}
                        </Button>
                      </Row>
                    </Col>
                  )}
                </div>
              </Col>
              <div className="candidate-view-col-container2">
                <Col xs={12} sm={12} md={12} className="candidate-view-col4">
                  <Row className="candidate-view-col3-title-row">
                    <h1 className="candidate-view-col3-title">{t('candidateView.col2_title')}</h1>
                  </Row>
                  <div className="candidate-view-col3-info-container">
                    <Col xs={12} sm={12} md={12}>
                      <div className="candidate-view-col4-info candidate-view-col4-info-mobile">
                        <h3 className="candidate-view-info-field-title-col4">{t('candidateView.submition_date')}</h3>
                        <text className={isValid ? 'candidate-view-info-field-data-col4 mobile' : 'candidate-view-info-field-data-col4 not-filled-required mobile'}>{isValid ? formatDate(submitionDate) : t('candidateView.not_valid')}</text>
                      </div>
                      {phases && phases.map((phase) => (
                        <div className="candidate-view-col4-info" key={phase.id}>
                          <h3 className="candidate-view-info-field-title-col4">{phase.name}</h3>
                          {phase.current ? ( // eslint-disable-line
                            userType === 'candidate' && (phase.status === 'ongoing' || phase.status === 'approved' || phase.status === 'rejected') ? ( // eslint-disable-line
                              <Button className="availabilities-button mobile">
                                {t('candidateView.schedule')}
                              </Button>
                            ) : (
                              phase.status === 'ongoing' ? (
                                <div className="application-buttons">
                                  <Button className="approve-button mobile" onClick={handleApprove}>
                                    {t('candidateView.approve')}
                                  </Button>
                                  <Button className="reject-button mobile" onClick={handleReject}>
                                    {t('candidateView.reject')}
                                  </Button>
                                </div>
                              ) : (
                                <div className="status-clear-container-mobile mobile">
                                  <text className="candidate-view-info-field-data-col4 mobile">{candidatePhaseInfo(phase)}</text>
                                  <Button className="clear-button mobile" onClick={handleClear}>
                                    {t('candidateView.clear')}
                                  </Button>
                                </div>
                              )
                            )
                          ) : (
                            phase.status === 'excluded' && phases.slice(0, phase.id - 1).some((p) => p.current === true && p.status !== 'excluded') ? (
                              <text className="candidate-view-info-field-data-col4 mobile">{t('candidateView.not_opened')}</text>
                            ) : (
                              <text className="candidate-view-info-field-data-col4 mobile">{candidatePhaseInfo(phase)}</text>
                            )
                          )}
                        </div>
                      ))}
                    </Col>
                    <Col xs={12} sm={12} md={12}>
                      <div className="candidate-view-col4-info">
                        <text className={isValid ? 'candidate-view-info-field-data-col4 desktop' : 'candidate-view-info-field-data-col4 desktop not-filled-required'}>{isValid ? formatDate(submitionDate) : t('candidateView.not_valid')}</text>
                      </div>
                      {phases && phases.map((phase) => (
                        <div className="candidate-view-col4-info" key={phase.id}>
                          {phase.current ? ( // eslint-disable-line
                            userType === 'candidate' && (phase.status === 'ongoing' || phase.status === 'approved' || phase.status === 'rejected') ? ( // eslint-disable-line
                              <Button className="availabilities-button desktop">
                                {t('candidateView.schedule')}
                              </Button>
                            ) : (
                              phase.status === 'ongoing' ? (
                                <>
                                  <Button className="approve-button desktop" onClick={handleApprove}>
                                    {t('candidateView.approve')}
                                  </Button>
                                  <Button className="reject-button desktop" onClick={handleReject}>
                                    {t('candidateView.reject')}
                                  </Button>
                                </>
                              ) : (
                                <div className="status-clear-container">
                                  <text className="candidate-view-info-field-data-col4 desktop">{candidatePhaseInfo(phase)}</text>
                                  {phase.status !== 'excluded' && (
                                    <Button className="clear-button desktop" onClick={handleClear}>
                                      {t('candidateView.clear')}
                                    </Button>
                                  )}
                                </div>
                              )
                            )
                          ) : (
                            phase.status === 'excluded' && phases.slice(0, phase.id - 1).some((p) => p.current === true && p.status !== 'excluded') ? (
                              <text className="candidate-view-info-field-data-col4 desktop">{t('candidateView.not_opened')}</text>
                            ) : (
                              <text className="candidate-view-info-field-data-col4 desktop">{candidatePhaseInfo(phase)}</text>
                            )
                          )}
                        </div>
                      ))}
                    </Col>
                  </div>
                </Col>
                <Col xs={24} sm={24} md={12} className="candidate-view-col5">
                  <Row className="candidate-view-col3-title-row">
                    <img src={MessageIcon} alt="Application" className="candidate-view-col3-icon" />
                    <h1 className="candidate-view-col3-title">{t('candidateView.col3_title')}</h1>
                  </Row>
                  {candidateMessage && originalMessage && !isEditingMessage ? (
                    <>
                      {candidateMessage && candidateMessage[selectedLanguage].split(/(?<=[.!?])\s+/).map((sentence) => (
                        <p className="candidate-view-col5-text" key={sentence}>{sentence}</p>
                      ))}
                      { userType !== 'candidate' && (
                        <Button className="edit-application-button" onClick={handleEditMessageClick}>
                          {t('candidateView.edit_message')}
                        </Button>
                      )}
                    </>
                  ) : (
                    <div>
                      <div className="candidate-view-col5-info">
                        <h3 className="candidate-view-info-field-title">PT</h3>
                        <Input.TextArea
                          className="candidate-view-input"
                          value={originalMessage.pt}
                          onChange={(e) => setOriginalMessage({ ...originalMessage, pt: e.target.value })} // eslint-disable-line
                        />
                      </div>
                      <div className="candidate-view-col5-info">
                        <h3 className="candidate-view-info-field-title">EN</h3>
                        <Input.TextArea
                          className="candidate-view-input"
                          value={originalMessage.en}
                          onChange={(e) => setOriginalMessage({ ...originalMessage, en: e.target.value })} // eslint-disable-line
                        />
                      </div>
                      <Row className="candidate-view-col3-button-row">
                        <Button className="save-application-button" onClick={handleCandidateEdit}>
                          {t('candidateView.save')}
                        </Button>
                        <Button className="cancel-edit-button" onClick={handleCancelMessageClick}>
                          {t('candidateView.cancel')}
                        </Button>
                      </Row>
                    </div>
                  )}
                </Col>
              </div>
            </div>
          </Row>
        </Col>
      </Row>
      <ConfirmAction
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default CandidateView;
