import { create } from 'zustand';

const authUserStore = create((set) => ({
  authUser: { permissions: [] },
  setAuthUser: (authUser) => set({ authUser }),
  userType: '',
  setUserType: (userType) => set({ userType }),
}));

export default authUserStore;
