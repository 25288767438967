import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Col, Row, Input, Modal, Button, Checkbox, Collapse, notification,
} from 'antd';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from '../axios';
import ConfirmAction from './ConfirmAction';
import '../css/createRoles.css';
import languageStore from '../stores/languageStore';

const { TextArea } = Input;

function CreateRole({
  open, setOpen, fetchRoles,
}) {
  const navigate = useNavigate();
  const [t] = useTranslation('global');

  const languages = ['pt', 'en'];
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const [names, setNames] = useState(Array(languages.length).fill(''));
  const [descriptions, setDescriptions] = useState(Array(languages.length).fill(''));
  const [selectedPermissions, setSelectedPermissions] = useState([[]]);
  const [permissionSubjects, setPermissionSubjects] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  const [activeKeys, setActiveKeys] = useState();

  const [masterCheckbox, setMasterCheckbox] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    } else {
      notification[type]({
        message: t('notification.warning'),
        description: message,
      });
    }
  };

  const handleCheckboxChange = (subjectIndex, checkedValues, allPermissions) => {
    console.log('subjectIndex', subjectIndex);
    console.log('checkedValues', checkedValues);
    console.log('allPermissions', allPermissions);
    const updatedCheckedValues = [...checkedValues];
    allPermissions.forEach((permission) => {
      if (updatedCheckedValues.includes(permission.id)) {
        if (!permission.action.startsWith('read')) {
          const seePermissionId = allPermissions.find((perm) => perm.subject === permission.subject && perm.action.startsWith('read'))?.id;
          if (seePermissionId && !updatedCheckedValues.includes(seePermissionId)) {
            updatedCheckedValues.push(seePermissionId);
            openNotificationWithIcon('info', t('roles.permissions_required'));
          }
        }
      }
    });
    const updatedPermissions = [...selectedPermissions];
    updatedPermissions[subjectIndex] = updatedCheckedValues;
    setSelectedPermissions(updatedPermissions);

    // Check if all permissions are selected
    const totalSelectedPermissions = updatedPermissions.flat().length;
    const totalAvailablePermissions = permissions.length;
    if (totalSelectedPermissions === totalAvailablePermissions) {
      setMasterCheckbox(true);
    } else {
      setMasterCheckbox(false);
    }
  };

  const [indeterminate, setIndeterminate] = useState(new Array(permissionSubjects.length)
    .fill(false));
  const [checkAll, setCheckAll] = useState(new Array(permissionSubjects.length).fill(false));

  const onCheckAllChange = (e, subjectIndex) => {
    const perms = [...selectedPermissions]; // Use let to allow reassignment
    if (!perms[subjectIndex]) {
      perms[subjectIndex] = []; // Ensure perms[subjectIndex] exists
    }
    perms[subjectIndex] = e.target.checked
      ? permissions.filter((permission) => permission.subject === permissionSubjects[subjectIndex])
        .map((permission) => permission.id)
      : [];
    setSelectedPermissions(perms);
    setIndeterminate(indeterminate.map((ind, i) => (i === subjectIndex ? false : ind)));
    setCheckAll(checkAll.map((chk, i) => (i === subjectIndex ? e.target.checked : chk)));

    // Check if all permissions are selected
    const totalSelectedPermissions = perms.flat().length;
    const totalAvailablePermissions = permissions.length;
    if (totalSelectedPermissions === totalAvailablePermissions) {
      setMasterCheckbox(true);
    } else {
      setMasterCheckbox(false);
    }
  };

  // Check box Select All
  const handleMasterCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setMasterCheckbox(isChecked);
    const updatedSelectedPermissions = isChecked
      ? permissionSubjects.map((subject) => permissions
        .filter((permission) => permission.subject === subject)
        .map((permission) => permission.id))
      : [];
    setSelectedPermissions([...updatedSelectedPermissions]);
  };

  function generateItems(permissionSubjs = permissionSubjects, perms = permissions) {
    return permissionSubjs.map((subject, index) => {
      const subjectPermissionIds = perms
        .filter((permission) => permission.subject === subject)
        .map((permission) => permission.id);
      const isAllChecked = subjectPermissionIds
        .every((id) => selectedPermissions[index]?.includes(id));
      return {
        key: subject,
        label: t(`role.${subject}`),
        children: (
          <div className="create-role-checkboxes">
            <div className="select-all-checkbox">
              <Checkbox
                onChange={(e) => onCheckAllChange(e, index)}
                checked={isAllChecked}
              >
                {t('roles.select_all')}
              </Checkbox>
            </div>
            <Checkbox.Group
              style={{ width: '100%' }}
              onChange={(checkedValues) => {
                handleCheckboxChange(index, checkedValues, perms);
              }}
              value={selectedPermissions[index] || []}
            >
              <Row>
                {subjectPermissionIds.map((id) => (
                  <Col span={12} key={id}>
                    <Checkbox value={id}>
                      {`${t(`role.${perms.find((p) => p.id === id).action}`)}`}
                    </Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </div>
        ),
      };
    });
  }

  useEffect(() => {
    setItems(generateItems());
  }, [selectedLanguage]);

  useEffect(() => {
    axios.get('/api/role/permission')
      .then((response) => {
        const subjects = response.data.map((permission) => permission.subject);
        setPermissionSubjects([...new Set(subjects)]);
        setPermissions(response.data);
        const generatedItems = generateItems([...new Set(subjects)], response.data);
        setItems(generatedItems);
      })
      .catch((error) => {
        console.error('Error fetching Permissions:', error);
      });
  }, []);

  useEffect(() => {
    setItems(generateItems());
  }, [selectedPermissions]);

  const handleCancel = () => {
    setOpen(false);
  };

  function mergeArraysToObject(arr) {
    const obj = {};
    languages.forEach((element, index) => {
      obj[element] = arr[index];
    });
    return obj;
  }

  const handleCreateRole = async () => {
    setLoading(true);
    const flatPermissions = [].concat(...selectedPermissions).filter((item) => item !== undefined);
    // convert flat permissions to json structure
    const jsonPermissions = [];
    for (let i = 0; i < flatPermissions.length; i += 1) {
      const permissionID = flatPermissions[i] - 1;
      jsonPermissions.push({
        id: permissionID + 1,
        action: permissions[permissionID].action,
        subject: permissions[permissionID].subject,
        conditions: permissions[permissionID].conditions,
      });
    }
    const jsonNames = mergeArraysToObject(names);
    const jsonDescriptions = mergeArraysToObject(descriptions);
    try {
      const response = await axios.post(
        '/api/role/create',
        {
          name: jsonNames,
          description: jsonDescriptions,
          companyID: '1',
          permissions: JSON.stringify(jsonPermissions),
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('role.create_success'));
        setNames(Array(languages.length).fill(''));
        setDescriptions(Array(languages.length).fill(''));
        setSelectedPermissions([[]]);
        setActiveKeys([]);
        setMasterCheckbox(false);
        setLoading(false);
        await fetchRoles();
        setOpen(false);
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 406:
            openNotificationWithIcon('error', t('role.create_406'));
            break;
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            navigate('/login');
            break;
          case 400:
            openNotificationWithIcon('error', t('role.create_400'));
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('role.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('role.create_error'));
      }
      setLoading(false);
    }
  };

  const onChange = (key) => {
    setActiveKeys(key);
  };

  const handleCreateRoleConfirmation = () => {
    handleCreateRole();
  };

  const showConfirmationModal = () => {
    setShowConfirmation(true);
  };

  const updateNames = (name, index) => {
    const updatedArray = names.map((item, i) => {
      if (i === index) {
        return name; // Update the item at the specified index
      }
      return item;
    });
    setNames(updatedArray);
  };

  const updateDescriptions = (description, index) => {
    const updatedArray = descriptions.map((item, i) => {
      if (i === index) {
        return description; // Update the item at the specified index
      }
      return item;
    });
    setDescriptions(updatedArray);
  };

  return (
    <div>
      <Modal
        open={open}
        title={<h2 className="create-role-title">{t('role.create_role')}</h2>}
        onCancel={handleCancel}
        centered
        footer={null}
        className="create-role-popup"
        width="750px"
      >
        <form onSubmit={handleCreateRole} className="create-role-form" id="create-role-form">
          {languages.map((language, index) => (
            <>
              <p className="create-role-name">
                {t('role.name_in')}
                {t(`languages.${language}`)}
                :
              </p>
              <Input
                type="text"
                id="name"
                name="name"
                maxLength={50}
                showCount
                value={names[index]}
                onChange={(e) => updateNames(e.target.value, index)}
              />
            </>
          ))}
          {languages.map((language, index) => (
            <>
              <p className="create-role-name">
                {t('role.description_in')}
                {t(`languages.${language}`)}
                :
              </p>
              <TextArea
                rows={3}
                value={descriptions[index]}
                maxLength={500}
                showCount
                onChange={(e) => updateDescriptions(e.target.value, index)}
              />
            </>
          ))}
          <p className="create-role-name">{t('role.permissions')}</p>
          <Checkbox onChange={handleMasterCheckboxChange} checked={masterCheckbox}>{t('roles.select_all')}</Checkbox>
          <Collapse ghost items={items} onChange={onChange} activeKey={activeKeys} />
          <Button className="create-role-button" type="button" loading={loading} onClick={showConfirmationModal}>
            {t('role.create')}
          </Button>
        </form>
        <ConfirmAction
          open={showConfirmation}
          setOpen={setShowConfirmation}
          onConfirm={handleCreateRoleConfirmation}
        />
      </Modal>
    </div>
  );
}

CreateRole.propTypes = {
  open: PropTypes.isRequired,
  setOpen: PropTypes.isRequired,
  fetchRoles: PropTypes.isRequired,
};

export default CreateRole;
