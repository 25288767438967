import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsForm from './ContactUsForm';
import '../css/aboutUs.css';

function AboutUs() {
  const [t] = useTranslation('global');

  const [open, setOpen] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div className="about-us-component">
      <div className="about-us-text-div">
        <div className="about-us-gradient-div">
          <h1 className="about-us-title">{t('about_us.title')}</h1>
          <div className="gradient-line-about-us" />
        </div>
        <p className="about-us-description">
          Júnior Empresa da Faculdade de Engenharia no Porto. Temos +60 membros e
          +170 projetos concluídos! Submete o teu currículo e descobre mais sobre
          nós!
        </p>
      </div>
      <button
        type="submit"
        onClick={showModal}
        className="about-us-contact-button"
      >
        {t('about_us.contact_button')}
      </button>
      <ContactUsForm
        open={open}
        setOpen={setOpen}
      />
    </div>
  );
}

export default AboutUs;
