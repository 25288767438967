import React from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import languageStore from '../stores/languageStore';
import '../css/confirmAction.css';

function AdvanceConflicts({
  open, setOpen, name, conflicts,
}) {
  const [t] = useTranslation('global');
  const selectedLanguage = languageStore.getState().currentLanguage;
  let parsedConflicts;

  try {
    parsedConflicts = JSON.parse(conflicts);
  } catch (error) {
    console.error('Failed to parse conflicts JSON:', error);
    parsedConflicts = [];
  }

  const handleOk = () => {
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        title={[
          <p className="conflicts-title">
            {t('confirmModal.cant_advance')}
            <strong>{name}</strong>
            {t('confirmModal.cant_advance2')}
          </p>,
        ]}
        centered
        className="confirm-action-modal"
        onCancel={handleOk}
        width="550px"
        footer={[
          <button className="confirm-action-button-ok" key="submit" type="button" onClick={handleOk}>
            {t('confirmModal.ok')}
          </button>,
        ]}
      >
        <div className="conflicts-container">
          {parsedConflicts.map((conflict) => (
            <div key={conflict.id} className="conflict">
              <p>
                <strong>
                  {t(`menu.${conflict.type.toLowerCase()}`)}
                  :&nbsp;
                  {conflict.count}
                </strong>
              </p>
              <ul>
                {conflict.details.map((detail) => (
                  <li key={detail.id}>
                    {conflict.type === 'Jobs' ? (
                      <span>{detail.name[selectedLanguage]}</span>
                    ) : (
                      <span>{detail.name}</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
}

AdvanceConflicts.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  conflicts: PropTypes.string.isRequired,
};

export default AdvanceConflicts;
