import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Input, Modal, Button, notification, Col, Row,
} from 'antd';
import PropTypes from 'prop-types';
import EmailIcon from '../images/email_icon.svg';
import PencilIcon from '../images/pencil.svg';
import '../css/contactUsForm.css';

import axios from '../axios';

const { TextArea } = Input;

function ContactUsForm({
  open, setOpen,
}) {
  const [t] = useTranslation('global');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');

  const [loading, setLoading] = useState(false);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleCancel = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);

    if (!name || !email || !subject || !body) {
      alert('Please fill out all fields.');
      return;
    }

    const formData = {
      name,
      email,
      subject,
      body,
    };

    try {
      axios.post('api/user/sendEmail', formData);
      openNotificationWithIcon('success', t('contact_us.submit_successful'));
    } catch (error) {
      openNotificationWithIcon('error', t('contact_us.submit_failed'));
      setLoading(false);
      setOpen(false);
    }

    setName('');
    setEmail('');
    setSubject('');
    setBody('');
    setLoading(false);
    setOpen(false);
  };

  return (
    <div>
      <Modal
        open={open}
        onCancel={handleCancel}
        centered
        footer={null}
        className="contact-us-popup"
        width="650px"
      >
        <div>
          <form onSubmit={handleSubmit} className="contact-us-form" id="contact-us-form">
            <Row>
              <Col xs={24} sm={24} md={24}>
                <div className="contact-us-wrapper">
                  <img src={EmailIcon} alt="Email Icon" className="contact-us-email-icon" />
                  <h2 className="contact-us-title">{t('contact_us.title')}</h2>
                  <div className="gradient-line" />
                  <div className="contact-us-input">
                    <img src={PencilIcon} alt="Pencil" className="contact-us-pencil-icon" />
                    <p className="contact-us-field-name">
                      {t('contact_us.full_name')}
                    </p>
                  </div>
                  <Input
                    type="text"
                    id="name"
                    name="name"
                    maxLength={50}
                    variant="filled"
                    style={{
                      height: 45,
                      resize: 'none',
                    }}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  <div className="contact-us-input">
                    <img src={PencilIcon} alt="Pencil" className="contact-us-pencil-icon" />
                    <p className="contact-us-field-name">
                      Email
                    </p>
                  </div>
                  <Input
                    type="text"
                    id="email"
                    name="email"
                    maxLength={50}
                    variant="filled"
                    style={{
                      height: 45,
                      resize: 'none',
                    }}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="contact-us-input">
                    <img src={PencilIcon} alt="Pencil" className="contact-us-pencil-icon" />
                    <p className="contact-us-field-name">
                      {t('contact_us.subject')}
                    </p>
                  </div>
                  <Input
                    type="text"
                    id="subject"
                    name="subject"
                    maxLength={50}
                    variant="filled"
                    style={{
                      height: 45,
                      resize: 'none',
                    }}
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <div className="contact-us-input">
                    <img src={PencilIcon} alt="Pencil" className="contact-us-pencil-icon" />
                    <p className="contact-us-field-name">
                      {t('contact_us.message')}
                    </p>
                  </div>
                  <TextArea
                    rows={3}
                    variant="filled"
                    value={body}
                    onChange={(e) => setBody(e.target.value)}
                  />
                  <p className="contact-us-extra">{t('contact_us.extra')}</p>
                  <Button className="contact-us-button" type="button" size="large" loading={loading} onClick={handleSubmit}>
                    {t('contact_us.send')}
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </div>

      </Modal>
    </div>
  );
}

ContactUsForm.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default ContactUsForm;
