import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import {
  Row,
  Col,
  Input,
  Collapse,
  Select,
  Button,
  Checkbox,
  DatePicker,
  notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import { PhoneInput } from 'react-international-phone';
import { IoAddCircleOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { LuPencil } from 'react-icons/lu';
import axios from '../axios';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmAction from '../components/ConfirmAction';
import languageStore from '../stores/languageStore';
import '../css/createApplicationForms.css';

const { Panel } = Collapse;

function CreateApplicationForms() {
  const [t] = useTranslation('global');
  const companyID = 1;
  const [darkLogo, setDarkLogo] = useState('');
  const [jobs, setJobs] = useState([]);

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  useEffect(() => {
    axios.get('/api/job')
      .then((response) => {
        setJobs(response.data);
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error);
        openNotificationWithIcon('error', t('job.fetch_failed'));
      });
  }, []);

  const options = jobs.map((job) => ({
    value: job.name[selectedLanguage],
    label: job.name[selectedLanguage],
  }));

  const initialApplicationFields = [
    {
      id: 1,
      pt: 'Nome',
      en: 'Name',
      type: 'text',
      placeholder: t('applicationForm.text'),
      isRequired: true,
    },
    {
      id: 2,
      pt: 'Email',
      en: 'Email',
      type: 'text',
      placeholder: 'Email',
    },
    {
      id: 3,
      pt: 'Género',
      en: 'Gender',
      type: 'select',
      placeholder: t('applicationForm.gender'),
      options: [{ value: { pt: 'Masculino', en: 'Male' }, pt: 'Masculino', en: 'Male' }, { value: { pt: 'Feminino', en: 'Female' }, pt: 'Feminino', en: 'Female' }, { value: { pt: 'Outro', en: 'Other' }, pt: 'Outro', en: 'Other' }],
      isRequired: true,
    },
    {
      id: 4,
      pt: 'Contacto',
      en: 'Contact',
      type: 'contact',
      placeholder: t('applicationForm.number'),
      isRequired: true,
    },
    {
      id: 5,
      pt: 'Data de Nascimento',
      en: 'Birth Date',
      type: 'date',
      placeholder: t('applicationForm.date_format'),
      isRequired: true,
    },
    {
      id: 6,
      pt: 'Universidade',
      en: 'University',
      type: 'text',
      placeholder: t('applicationForm.university'),
      isRequired: true,
    },
    {
      id: 7,
      pt: 'Curso',
      en: 'Degree',
      type: 'text',
      placeholder: t('applicationForm.degree'),
      isRequired: true,
    },
    {
      id: 8,
      pt: 'Ano',
      en: 'Year',
      type: 'select',
      placeholder: t('applicationForm.year'),
      options: [{ value: { pt: '1', en: '1' }, pt: '1º', en: '1º' }, { value: { pt: '2', en: '2' }, pt: '2º', en: '2º' }, { value: { pt: '3', en: '3' }, pt: '3º', en: '3º' }, { value: { pt: '4', en: '4' }, pt: '4º', en: '4º' }, { value: { pt: '5', en: '5' }, pt: '5º', en: '5º' }],
      isRequired: true,
    },
    {
      id: 9,
      pt: 'CV',
      en: 'CV',
      type: 'file',
      placeholder: t('applicationForm.choose_file'),
      isRequired: true,
    },
    {
      id: 10,
      pt: 'Carta de Motivação',
      en: 'Motivation Letter',
      type: 'file',
      placeholder: t('applicationForm.choose_file'),
      isRequired: false,
    },
    {
      id: 11,
      pt: 'Primeira Opção',
      en: 'First Option',
      type: 'select',
      placeholder: t('applicationForm.first_option'),
      isRequired: true,
    },
    {
      id: 12,
      pt: 'Segunda Opção',
      en: 'Second Option',
      type: 'select',
      placeholder: t('applicationForm.second_option'),
      isRequired: false,
    },
  ];

  const [formsName, setFormsName] = useState('');

  const [applicationFields, setApplicationFields] = useState([
    ...initialApplicationFields,
  ]);
  const [selectedInputType, setSelectedInputType] = useState(null);
  const [newInputNamePT, setNewInputNamePT] = useState('');
  const [newInputNameEN, setNewInputNameEN] = useState('');
  const [newInputOptions, setNewInputOptions] = useState([]);
  const [languageNewInputOptions, setLanguageNewInputOptions] = useState([]);
  const [optionNamePT, setOptionNamePT] = useState('');
  const [optionNameEN, setOptionNameEN] = useState('');
  const [editingFieldId, setEditingFieldId] = useState(null);
  const [isRequired, setIsRequired] = useState(false);
  const [newInputIsRequired, setNewInputIsRequired] = useState(false);
  const [hasDynamicPlaceholder, setHasDynamicPlaceholder] = useState(false);
  const [newInputPlaceholderPT, setNewInputPlaceholderPT] = useState('');
  const [newInputPlaceholderEN, setNewInputPlaceholderEN] = useState('');

  const [fileFormat, setFileFormat] = useState([]);

  const filterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleInputChange = (value) => {
    setSelectedInputType(value);
  };

  const getType = (type) => {
    if (type === 'date') return 'date';
    if (type === 'number') return 'number';
    if (type === 'file') return 'file';
    if (type === 'contact') return 'contact';
    if (type === 'select') return 'select';
    if (type === 'text') return 'text';
    if (type === 'long text') return 'long text';
    return '';
  };

  const handleAddField = () => {
    if (selectedInputType === 'select' && newInputOptions.length === 0) {
      openNotificationWithIcon('error', t('applicationForm.option_error'));
      return;
    }
    if (!newInputNamePT || !newInputNameEN) {
      openNotificationWithIcon('error', t('applicationForm.field_name_error'));
      return;
    }
    if (!selectedInputType) {
      openNotificationWithIcon('error', t('applicationForm.input_type_error'));
      return;
    }
    if (newInputNamePT && newInputNameEN && selectedInputType) { // eslint-disable-line
      const newField = {
        id: applicationFields.length + 1,
        pt: newInputNamePT,
        en: newInputNameEN,
        type: getType(selectedInputType),
        placeholder: { pt: newInputPlaceholderPT, en: newInputPlaceholderEN },
        fileFormat,
        options: newInputOptions,
        isRequired,
      };

      setApplicationFields([...applicationFields, newField]);
      setNewInputNamePT('');
      setNewInputNameEN('');
      setSelectedInputType(null);
      setFileFormat([]);
      setNewInputOptions([]);
      setOptionNamePT('');
      setOptionNameEN('');
      setNewInputIsRequired(false);
      setHasDynamicPlaceholder(false);
      setNewInputPlaceholderPT('');
      setNewInputPlaceholderEN('');
      setIsRequired(false);
    }
  };

  const handleEditClick = (fieldId, fieldNamePT, fieldNameEN, fieldIsRequired, fieldPlaceholder) => { // eslint-disable-line
    setEditingFieldId(fieldId);
    setNewInputNamePT(fieldNamePT);
    setNewInputNameEN(fieldNameEN);
    setNewInputIsRequired(fieldIsRequired);
    if (fieldPlaceholder.pt && fieldPlaceholder.en) {
      setHasDynamicPlaceholder(true);
      setNewInputPlaceholderPT(fieldPlaceholder.pt);
      setNewInputPlaceholderEN(fieldPlaceholder.en);
    }
  };

  const handleCancelEdit = () => {
    setEditingFieldId(null);
    setNewInputNamePT('');
    setNewInputNameEN('');
    setNewInputIsRequired(false);
    setHasDynamicPlaceholder(false);
    setNewInputPlaceholderEN('');
    setNewInputPlaceholderPT('');
  };

  const handleEditCreatedFieldName = (id) => {
    if (!newInputNamePT || !newInputNameEN) {
      openNotificationWithIcon('error', t('applicationForm.field_name_error'));
      return;
    }
    if (hasDynamicPlaceholder && (!newInputPlaceholderPT || !newInputPlaceholderEN)) {
      openNotificationWithIcon('error', t('applicationForm.placeholder_error'));
      return;
    }
    const updatedFields = applicationFields.map((field) => {
      if (field.id === id) {
        return {
          ...field,
          pt: newInputNamePT,
          en: newInputNameEN,
          isRequired: newInputIsRequired,
          placeholder: { pt: newInputPlaceholderPT, en: newInputPlaceholderEN },
        };
      }
      return field;
    });
    setApplicationFields(updatedFields);
    setNewInputNamePT('');
    setNewInputNameEN('');
    setNewInputIsRequired(false);
    setHasDynamicPlaceholder(false);
    setNewInputPlaceholderEN('');
    setNewInputPlaceholderPT('');
    setEditingFieldId(null);
  };

  const handleDeleteField = (id) => {
    const updatedFields = applicationFields.filter((field) => field.id !== id);
    setApplicationFields(updatedFields);
  };

  const handleCheckboxTechnicalProblemFormat = (format, checked) => {
    const updatedFormats = checked
      ? [...fileFormat, format]
      : fileFormat.filter((val) => val !== format);
    setFileFormat(updatedFormats);
  };

  const handleNewInputOptions = () => {
    if (!optionNamePT || !optionNameEN) {
      openNotificationWithIcon('error', t('applicationForm.option_name_error'));
      return;
    }
    setNewInputOptions((prevOptions) => [
      ...prevOptions,
      {
        value: { pt: optionNamePT, en: optionNameEN },
        pt: optionNamePT,
        en: optionNameEN,
      },
    ]);
    setOptionNamePT('');
    setOptionNameEN('');
  };

  const handleRemoveLastOption = () => {
    setNewInputOptions((prevOptions) => prevOptions.slice(0, prevOptions.length - 1));
  };

  useEffect(() => {
    setNewInputOptions([]);
  }, [selectedInputType]);

  useEffect(() => {
    setLanguageNewInputOptions(newInputOptions.map((option) => ({
      value: option.value[selectedLanguage],
      label: selectedLanguage === 'pt' ? option.pt : option.en,
    })));
  }, [newInputOptions, selectedLanguage]);

  useEffect(() => {
    // This effect will run whenever newInputNamePT or newInputNameEN changes
  }, [newInputNamePT, newInputNameEN]);

  const renderPreviewInput = () => {
    switch (selectedInputType) {
      case 'date':
        return (
          <div className="app-forms-add-field-preview-section">
            <div className="app-forms-preview-section-header">
              <p className="app-forms-label">
                {selectedLanguage === 'pt'
                  ? (newInputNamePT || t('applicationForm.field_name'))
                  : (newInputNameEN || t('applicationForm.field_name'))}
              </p>
            </div>
            <DatePicker
              format={selectedLanguage === 'pt' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
              placeholder={t('applicationForm.date_format')}
            />
          </div>
        );
      case 'file':
        return (
          <div className="app-forms-add-field-preview-section-file">
            <div className="add-input-file-format-div">
              <p className="add-input-file-format">
                {t('applicationForm.file_format')}
                :
              </p>
              <div className="phase-technical-problem-format-checkboxes">
                <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat('pdf', e.target.checked)}>PDF</Checkbox>
                <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat('image', e.target.checked)}>{t('applicationForm.image')}</Checkbox>
                <Checkbox onChange={(e) => handleCheckboxTechnicalProblemFormat('other', e.target.checked)}>{t('applicationForm.all')}</Checkbox>
              </div>
            </div>
          </div>
        );
      case 'text':
        return (
          <div className="app-forms-add-field-preview-section">
            <div className="app-forms-preview-section-header">
              <p className="app-forms-label">
                {selectedLanguage === 'pt'
                  ? (newInputNamePT || t('applicationForm.field_name'))
                  : (newInputNameEN || t('applicationForm.field_name'))}
              </p>
            </div>
            <Input
              id="newFieldName"
              className="app-forms-preview-field"
              placeholder={t('applicationForm.text')}
              type="text"
              maxLength={50}
            />
          </div>
        );
      case 'long text':
        return (
          <div className="app-forms-add-field-preview-section">
            <div className="app-forms-preview-section-header">
              <p className="app-forms-label">
                {selectedLanguage === 'pt'
                  ? (newInputNamePT || t('applicationForm.field_name'))
                  : (newInputNameEN || t('applicationForm.field_name'))}
              </p>
            </div>
            <Input.TextArea
              id="newFieldName"
              className="app-forms-preview-field"
              placeholder={t('applicationForm.text')}
              maxLength={500}
            />
          </div>
        );
      case 'number':
        return (
          <div className="app-forms-add-field-preview-section">
            <div className="app-forms-preview-section-header">
              <p className="app-forms-label">
                {selectedLanguage === 'pt'
                  ? (newInputNamePT || t('applicationForm.field_name'))
                  : (newInputNameEN || t('applicationForm.field_name'))}
              </p>
            </div>
            <Input
              id="newFieldName"
              className="app-forms-preview-field"
              placeholder={t('applicationForm.number')}
              type="number"
              maxLength={320}
            />
          </div>
        );
      case 'contact':
        return (
          <div className="app-forms-add-field-preview-section">
            <p className="app-forms-label">
              {selectedLanguage === 'pt'
                ? (newInputNamePT || t('applicationForm.field_name'))
                : (newInputNameEN || t('applicationForm.field_name'))}
            </p>
            <PhoneInput
              defaultCountry="pt"
              className="app-forms-preview-field-phone"
            />
          </div>
        );
      case 'select':
        return (
          <div className="app-forms-add-field-preview-section">
            <p className="app-forms-label">
              {t('applicationForm.options')}
              :
            </p>
            <div className="app-forms-input-section-header">
              <p className="app-forms-label">
                {t('applicationForm.field_option_name_PT')}
                :
              </p>
            </div>
            <Input
              className="app-forms-input-field"
              type="text"
              min="0"
              value={optionNamePT}
              placeholder={t('applicationForm.text')}
              onChange={(e) => setOptionNamePT(e.target.value)}
            />
            <div className="app-forms-input-section-header">
              <p className="app-forms-label">
                {t('applicationForm.field_option_name_EN')}
                :
              </p>
            </div>
            <Input
              className="app-forms-input-field"
              type="text"
              min="0"
              value={optionNameEN}
              placeholder={t('applicationForm.text')}
              onChange={(e) => setOptionNameEN(e.target.value)}
            />
            <div className="app-forms-option-buttons">
              <Button onClick={handleNewInputOptions} type="button" className="app-forms-add-option-button">
                {t('applicationForm.add_option')}
              </Button>
              <Button onClick={handleRemoveLastOption} type="button" className="app-forms-add-option-button">
                {t('applicationForm.remove_option')}
              </Button>
            </div>
            <div className="app-forms-preview-section-header">
              <p className="app-forms-label">
                {selectedLanguage === 'pt'
                  ? (newInputNamePT || t('applicationForm.field_name'))
                  : (newInputNameEN || t('applicationForm.field_name'))}
              </p>
            </div>
            <Select
              showSearch
              className="app-forms-preview-select"
              placeholder={t('applicationForm.select')}
              optionFilterProp="children"
              options={languageNewInputOptions}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleCreateApplicationForms = async () => {
    if (!formsName) {
      openNotificationWithIcon('error', t('applicationForm.name_error'));
      return;
    }
    try {
      const response = await axios.post(
        '/api/applicationForm/create',
        {
          applicationFields,
          name: formsName,
        },
      );
      if (response.status === 201) {
        openNotificationWithIcon('success', t('applicationForm.create_success'));
        setApplicationFields([...initialApplicationFields]);
        setNewInputNamePT('');
        setNewInputNameEN('');
        setSelectedInputType(null);
        setFileFormat([]);
        setFormsName('');
        navigate('/applicationForms');
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 401:
            openNotificationWithIcon('error', t('auth.need_login'));
            navigate('/login');
            break;
          case 403:
            openNotificationWithIcon('error', t('auth.not_authorized'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('role.create_error'));
        }
      } else {
        openNotificationWithIcon('error', t('season.create_error'));
      }
    }
  };

  const showModal = () => {
    setOpen(true);
  };

  return (
    <div className="create-app-forms">
      <Row className="create-app-forms-container">
        <Navbar />
        <NavbarMobile />
        <Col xs={24} sm={24} md={24} className="create-app-forms-page-col2">
          <img src={darkLogo} className="create-app-forms-header-logo" alt="logo" />
          <h1 className="create-app-forms-header">{t('applicationForm.title')}</h1>
          <div className="create-app-body">
            <div className="create-app-name">
              <p className="app-forms-name-input-label">
                {t('applicationForm.name')}
                :
              </p>
              <input
                className="app-forms-name-input-field"
                type="text"
                maxLength={320}
                value={formsName}
                onChange={(e) => setFormsName(e.target.value)}
              />
              <LuPencil className="create-app-name-icon" />
            </div>
            <form className="create-app-forms-form" onSubmit={handleCreateApplicationForms}>
              <div className="app-forms-fields-div">
                {applicationFields.map((field) => (
                  <div key={field.id} className="app-forms-input-section">
                    <div className="app-forms-input-section-header">
                      {editingFieldId !== field.id ? (
                        <div className="field-name-container">
                          <p className="app-forms-label">{selectedLanguage === 'pt' ? field.pt : field.en }</p>
                          <button
                            type="button"
                            className="app-forms-input-edit-btn"
                            aria-label={`Edit ${field}`}
                            onClick={() => handleEditClick(field.id, field.pt, field.en, field.isRequired, field.placeholder)} // eslint-disable-line
                          >
                            <LuPencil className="create-app-name-icon" />
                          </button>
                          <button
                            type="button"
                            className="app-forms-input-delete-btn"
                            aria-label={`Delete ${field}`}
                            onClick={() => handleDeleteField(field.id)}
                          >
                            <FaRegTrashAlt />
                          </button>
                        </div>
                      ) : (
                        <div className="app-forms-edit-field">
                          <div className="app-forms-edit-field-names-div">
                            <div className="app-forms-add-field-input-section">
                              <div className="app-forms-input-section-header">
                                <p className="app-forms-edit-label">
                                  {t('applicationForm.field_name_PT')}
                                  :
                                </p>
                              </div>
                              <Input
                                id="newFieldName"
                                className="app-forms-input-field"
                                placeholder={t('applicationForm.text')}
                                value={newInputNamePT}
                                onChange={(e) => setNewInputNamePT(e.target.value)}
                                type="text"
                                maxLength={320}
                              />
                            </div>
                            <div className="app-forms-add-field-input-section">
                              <div className="app-forms-input-section-header">
                                <p className="app-forms-edit-label">
                                  {t('applicationForm.field_name_EN')}
                                  :
                                </p>
                              </div>
                              <Input
                                id="newFieldName"
                                className="app-forms-input-field"
                                placeholder={t('applicationForm.text')}
                                value={newInputNameEN}
                                onChange={(e) => setNewInputNameEN(e.target.value)}
                                type="text"
                                maxLength={320}
                              />
                            </div>
                          </div>
                          { hasDynamicPlaceholder && (
                            <div className="app-forms-edit-field-names-div">
                              <div className="app-forms-add-field-input-section">
                                <div className="app-forms-input-section-header">
                                  <p className="app-forms-edit-label">
                                    {t('applicationForm.custom_placeholder_PT')}
                                    :
                                  </p>
                                </div>
                                <Input
                                  id="newFieldName"
                                  className="app-forms-input-field"
                                  placeholder={t('applicationForm.text')}
                                  value={newInputPlaceholderPT}
                                  onChange={(e) => setNewInputPlaceholderPT(e.target.value)}
                                  type="text"
                                  maxLength={320}
                                />
                              </div>
                              <div className="app-forms-add-field-input-section">
                                <div className="app-forms-input-section-header">
                                  <p className="app-forms-edit-label">
                                    {t('applicationForm.custom_placeholder_EN')}
                                    :
                                  </p>
                                </div>
                                <Input
                                  id="newFieldName"
                                  className="app-forms-input-field"
                                  placeholder={t('applicationForm.text')}
                                  value={newInputPlaceholderEN}
                                  onChange={(e) => setNewInputPlaceholderEN(e.target.value)}
                                  type="text"
                                  maxLength={320}
                                />
                              </div>
                            </div>
                          )}
                          <Checkbox
                            checked={newInputIsRequired}
                            onChange={(e) => setNewInputIsRequired(e.target.checked)}
                          >
                            {t('applicationForm.required')}
                          </Checkbox>
                          <Checkbox
                            checked={hasDynamicPlaceholder}
                            onChange={(e) => setHasDynamicPlaceholder(e.target.checked)}
                          >
                            {t('applicationForm.custom_placeholder')}
                          </Checkbox>
                          <div className="edit-button-container">
                            <Button
                              type="button"
                              onClick={() => handleEditCreatedFieldName(field.id)}
                              className="app-forms-edit-button"
                            >
                              {t('applicationForm.edit')}
                            </Button>
                            <Button
                              type="button"
                              onClick={() => handleCancelEdit()}
                              className="app-forms-edit-button"
                            >
                              {t('applicationForm.cancel')}
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                    {field.type === 'date' && (
                      <DatePicker
                        className="app-forms-input-date-input"
                        placeholder={t('applicationForm.date_format')}
                        format={selectedLanguage === 'pt' ? 'DD-MM-YYYY' : 'YYYY-MM-DD'}
                      />
                    )}
                    {field.type === 'file' && (
                      <div className="app-forms-input-file-input">
                        <input type="file" id={`file-input-${field.id}`} className="inputfile" />
                        <label htmlFor={`file-input-${field.id}`}>
                          {field.placeholder && (field.placeholder.pt || field.placeholder.en) ? ( // eslint-disable-line
                            selectedLanguage === 'pt' ? field.placeholder.pt : field.placeholder.en
                          ) : (
                            t('applicationForm.choose_file')
                          )}
                        </label>
                        <UploadOutlined className="upload-icon" />
                      </div>
                    )}
                    {field.type === 'contact' && (
                      <PhoneInput
                        defaultCountry="pt"
                        className="app-forms-preview-field-phone"
                      />
                    )}
                    {field.type === 'number' && (
                      <Input
                        className="app-forms-input-field"
                        placeholder={selectedLanguage === 'pt' ? field.placeholder.pt : field.placeholder.en}
                        type="number"
                        maxLength={320}
                      />
                    )}
                    {field.type === 'select' && (
                      <Select
                        showSearch
                        placeholder={selectedLanguage === 'pt' ? field.placeholder.pt : field.placeholder.en}
                        defaultValue={selectedLanguage === 'pt' ? field.placeholder.pt : field.placeholder.en}
                        optionFilterProp="children"
                        options={field.options ? (field.options.map((option) => ({
                          value: option.value[selectedLanguage],
                          label: option[selectedLanguage],
                        }))) : (options)}
                      />
                    )}
                    {field.type !== 'date' && field.type !== 'file' && field.type !== 'contact' && field.type !== 'number' && field.type !== 'select' && (
                      <Input
                        className="app-forms-input-field"
                        placeholder={selectedLanguage === 'pt' ? field.placeholder.pt : field.placeholder.en}
                        type={field.type}
                        maxLength={320}
                      />
                    )}
                  </div>
                ))}
              </div>
              <Collapse accordion ghost>
                <Panel
                  header={(
                    <div className="app-forms-add-field-header">
                      <p className="app-forms-add-field-label">{t('applicationForm.add_field')}</p>
                      <IoAddCircleOutline />
                    </div>
                  )}
                  showArrow={false}
                >
                  <div className="app-forms-add-field">
                    <div className="app-forms-add-field-names-div">
                      <div className="app-forms-add-field-input-section">
                        <div className="app-forms-input-section-header">
                          <p className="app-forms-label">
                            {t('applicationForm.field_name_PT')}
                            :
                          </p>
                        </div>
                        <Input
                          id="newFieldName"
                          className="app-forms-input-field"
                          placeholder={t('applicationForm.text')}
                          value={newInputNamePT}
                          onChange={(e) => setNewInputNamePT(e.target.value)}
                          type="text"
                          maxLength={320}
                        />
                      </div>
                      <div className="app-forms-add-field-input-section">
                        <div className="app-forms-input-section-header">
                          <p className="app-forms-label">
                            {t('applicationForm.field_name_EN')}
                            :
                          </p>
                        </div>
                        <Input
                          id="newFieldName"
                          className="app-forms-input-field"
                          placeholder={t('applicationForm.text')}
                          value={newInputNameEN}
                          onChange={(e) => setNewInputNameEN(e.target.value)}
                          type="text"
                          maxLength={320}
                        />
                      </div>
                    </div>
                    { hasDynamicPlaceholder && (
                      <div className="app-forms-add-field-names-div">
                        <div className="app-forms-add-field-input-section">
                          <div className="app-forms-input-section-header">
                            <p className="app-forms-label">
                              {t('applicationForm.custom_placeholder_PT')}
                              :
                            </p>
                          </div>
                          <Input
                            id="newFieldName"
                            className="app-forms-input-field"
                            placeholder={t('applicationForm.text')}
                            value={newInputPlaceholderPT}
                            onChange={(e) => setNewInputPlaceholderPT(e.target.value)}
                            type="text"
                            maxLength={320}
                          />
                        </div>
                        <div className="app-forms-add-field-input-section">
                          <div className="app-forms-input-section-header">
                            <p className="app-forms-label">
                              {t('applicationForm.custom_placeholder_EN')}
                              :
                            </p>
                          </div>
                          <Input
                            id="newFieldName"
                            className="app-forms-input-field"
                            placeholder={t('applicationForm.text')}
                            value={newInputPlaceholderEN}
                            onChange={(e) => setNewInputPlaceholderEN(e.target.value)}
                            type="text"
                            maxLength={320}
                          />
                        </div>
                      </div>
                    )}
                    <div className="app-forms-add-field-type-section">
                      <div className="app-forms-add-field-select-type">
                        <div className="app-forms-input-section-header">
                          <p className="app-forms-label">
                            {t('applicationForm.input_type')}
                            :
                          </p>
                        </div>
                        <Select
                          showSearch
                          placeholder={t('applicationForm.select')}
                          optionFilterProp="children"
                          filterOption={filterOption}
                          options={[
                            {
                              value: 'date',
                              label: t('applicationForm.date'),
                            },
                            {
                              value: 'file',
                              label: t('applicationForm.choose_file'),
                            },
                            {
                              value: 'text',
                              label: t('applicationForm.text'),
                            },
                            {
                              value: 'long text',
                              label: t('applicationForm.long_text'),
                            },
                            {
                              value: 'number',
                              label: t('applicationForm.number'),
                            },
                            {
                              value: 'contact',
                              label: t('applicationForm.contact'),
                            },
                            {
                              value: 'select',
                              label: t('applicationForm.select'),
                            },
                          ]}
                          onChange={handleInputChange}
                          value={selectedInputType}
                          style={{ width: '100%' }}
                        />
                      </div>
                      <div className="app-forms-preview-section">
                        {renderPreviewInput()}
                      </div>
                      <Checkbox onChange={(e) => setIsRequired(e.target.checked)}>{t('applicationForm.required')}</Checkbox>
                      <Checkbox onChange={(e) => setHasDynamicPlaceholder(e.target.checked)}>{t('applicationForm.custom_placeholder')}</Checkbox>
                    </div>
                    <Button type="primary" onClick={handleAddField} className="app-forms-add-button">
                      {t('applicationForm.add')}
                    </Button>
                  </div>
                </Panel>
              </Collapse>
              <Button type="button" onClick={showModal} className="app-forms-submit-button">
                {t('applicationForm.create')}
              </Button>
            </form>
          </div>
        </Col>
      </Row>
      <ConfirmAction
        open={open}
        setOpen={setOpen}
        onConfirm={handleCreateApplicationForms}
      />
    </div>
  );
}

export default CreateApplicationForms;
