import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function SeasonStats({
  candidates,
  cvPhases,
  dynamics,
}) {
  const [t] = useTranslation('global');

  // TODO - Modify so that it can support multiple numbers of phases
  const [candidatesWidth, setCandidatesWidth] = useState(0);
  const [cvPhasesWidth, setCvPhasesWidth] = useState(0);
  const [dynamicsWidth, setDynamicsWidth] = useState(0);

  useEffect(() => {
    if (candidates > 0) {
      setCandidatesWidth(100);
      setCvPhasesWidth((cvPhases / candidates) * 100);
      setDynamicsWidth((dynamics / candidates) * 100);
    } else {
      setCandidatesWidth(0);
      setCvPhasesWidth(0);
      setDynamicsWidth(0);
    }
  }, []);

  return (
    <div className="stats-section">
      <h3 className="stats-section-title">{t('season.number_of_candidates')}</h3>
      <div className="stats-rows-div">
        <div className="stats-row">
          <p className="stats-row-name">{t('season.candidates')}</p>
          <div className="stats-row-bar-div">
            <span className="stats-row-bar" style={{ width: `${candidatesWidth}%` }} />
          </div>
          <p className="stats-row-percentage">{candidates}</p>
        </div>
        <div className="stats-row">
          <p className="stats-row-name">{t('season.cv_phases')}</p>
          <div className="stats-row-bar-div">
            <span className="stats-row-bar" style={{ width: `${cvPhasesWidth}%` }} />
          </div>
          <p className="stats-row-percentage">{cvPhases}</p>
        </div>
        <div className="stats-row">
          <p className="stats-row-name">{t('season.dynamics')}</p>
          <div className="stats-row-bar-div">
            <span className="stats-row-bar" style={{ width: `${dynamicsWidth}%` }} />
          </div>
          <p className="stats-row-percentage">{dynamics}</p>
        </div>
      </div>
    </div>
  );
}

SeasonStats.propTypes = {
  candidates: PropTypes.number.isRequired,
  cvPhases: PropTypes.number.isRequired,
  dynamics: PropTypes.number.isRequired,
};

export default SeasonStats;
