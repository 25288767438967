import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Offer from './Offer';
import TEC from '../images/TEC_Branco_1.svg';
import COM from '../images/COM_Branco_1.svg';
import MD from '../images/MD_Branco_1.svg';
import RH from '../images/RH_Branco_1.svg';
import QC from '../images/QC_Branco_1.svg';
import languageStore from '../stores/languageStore';
import axios from '../axios';
import '../css/offers.css';

function Offers() {
  const [t] = useTranslation('global');
  const [offers, setOffers] = useState([]);
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const fetchJobs = async () => {
    try {
      const response = await axios.get('/api/job');
      setOffers(response.data);
      console.log('Offers:', response.data);
    } catch (error) {
      console.error('Error fetching Jobs:', error);
    }
  };

  const photoSelector = (name) => {
    switch (name) {
      case 'Tecnologia':
      case 'Tech':
        return TEC;
      case 'Marketing & Design':
        return MD;
      case 'Recursos Humanos':
      case 'Human Resources':
        return RH;
      case 'Comercial':
      case 'Commercial':
        return COM;
      case 'Qualidade & Controlo':
      case 'Quality & Control':
        return QC;
      default:
        return TEC;
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  return (
    <div className="offers">
      <div className="offers-gradient-div">
        <h1 className="offers-title">{t('offers.title')}</h1>
        <div className="gradient-line-offers" />
      </div>
      <div className="offers-container">
        {offers.map((offer) => (
          <Offer
            name={offer.name[selectedLanguage]}
            description={offer.description[selectedLanguage]}
            photo={photoSelector(offer.name[selectedLanguage])}
          />
        ))}
      </div>
    </div>
  );
}

export default Offers;
