import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import axios from '../axios';
import PhoneCallLogo from '../images/phone-call.svg';
import GroupLogo from '../images/group.svg';
import InterviewLogo from '../images/interview.svg';
import ProblemLogo from '../images/technical-problem.svg';
import RecruitmentPhase from './RecruitmentPhase';
import GuiadoCandidato from '../assets/GuiadoCandidato24_25.pdf';
import languageStore from '../stores/languageStore';
import '../css/recruitment.css';

function Recruitment() {
  const [t] = useTranslation('global');
  const [phases, setPhases] = useState([]);
  const companyID = 1;

  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  useEffect(() => {
    const fetchPhases = async () => {
      axios.get('/api/season/current', { params: { companyID } })
        .then((response) => {
          setPhases(response.data.phases);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    fetchPhases();
  }, []);

  const logoSelector = (name) => {
    switch (name) {
      case 'Entrevista Telefónica':
      case 'Phone Interview':
        return PhoneCallLogo;
      case 'Dinâmica de Grupo':
      case 'Group Dynamic':
        return GroupLogo;
      case 'Problema Técnico':
      case 'Technical Problem':
        return ProblemLogo;
      case 'Entrevista Presencial':
      case 'In-Person Interview':
        return InterviewLogo;
      default:
        return '';
    }
  };

  const onButtonClick = () => {
    const pdfUrl = { GuiadoCandidato };
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = 'GuiadoCandidato.pdf';
    link.click();
  };

  return (
    <div className="recruitment">
      <div className="recruitment-gradient-div">
        <h1 className="recruitment-title">{t('recruitment.title')}</h1>
        <div className="gradient-line-recruitment" />
      </div>
      <div className="recruitment-phases">
        {phases.map((phase, index) => (
          <React.Fragment key={phase.name}>
            <RecruitmentPhase
              // TODO: dynamic logo
              logoSrc={logoSelector(phase.type[selectedLanguage])}
              phaseName={phase.name}
            />
            {index < phases.length - 1 && <span className="recruitment-line" />}
          </React.Fragment>
        ))}
      </div>
      <button type="button" className="recruitment-guide-button" onClick={onButtonClick}>
        {t('recruitment.guide')}
      </button>
    </div>
  );
}

export default Recruitment;
