import React, { useState, useEffect } from 'react';
import InstagramLogo from '../images/instagram_logo.svg';
import FacebookLogo from '../images/facebook_logo.svg';
import LinkedinLogo from '../images/linkedin_logo.svg';
import TwitterLogo from '../images/twitter_logo.svg';
import YoutubeLogo from '../images/youtube_logo.svg';
import TiktokLogo from '../images/tiktok_logo.svg';
import SocialMediaButton from './SocialMediaButton';
import axios from '../axios';
import '../css/footer.css';

function Footer() {
  const companyID = 1;
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [lightLogo, setLightLogo] = useState('');

  const [linksData, setLinksData] = useState({
    instagramLink: '',
    linkedinLink: '',
    facebookLink: '',
    twitterLink: '',
    youtubeLink: '',
    tiktokLink: '',
  });
  const [hasLinks, setHasLinks] = useState(false);

  useEffect(() => {
    // Fetch settings from the API
    axios.get(`/api/settings/${companyID}`)
      .then((response) => {
        setEmail(response.data.email);
        setAddress(response.data.address);

        const filteredLinksData = Object.fromEntries(
          Object.entries(response.data.linksData).filter(([value]) => value !== ''),
        );
        setLinksData(filteredLinksData);
      })
      .catch((error) => {
        console.error('Error fetching settings:', error);
      });
  }, []);

  useEffect(() => {
    // Fetch logo
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setLightLogo(response.data.companyLogoLight);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
      });
  }, []);

  useEffect(() => {
    if (Object.values(linksData).filter((link) => (link) !== '').length > 0) {
      setHasLinks(true);
    }
  }, [linksData]);

  const calculateIconSize = () => {
    const numberOfLinks = Object.values(linksData).filter((link) => (link) !== '').length;
    if (numberOfLinks <= 3) {
      return 65;
    }
    return 65 - (numberOfLinks - 3) * 10; // Decrease icon size by 10px for each additional link
  };

  return (
    <footer className="footer-container">
      <div className="footer">
        <img src={lightLogo} alt="logo" className="footer-logo" />
        <div className={!hasLinks ? 'footer-info' : 'footer-info2'}>
          <p>{email}</p>
          <p>{address}</p>
          <p className="footer-credits">Powered by JuniFEUP©</p>
        </div>
        <div className="social-media-icons-container">
          {linksData.instagramLink && (
            <SocialMediaButton icon={InstagramLogo} altText="Instagram" link={linksData.instagramLink} iconSize={calculateIconSize()} />
          )}
          {linksData.facebookLink && (
            <SocialMediaButton icon={FacebookLogo} altText="Facebook" link={linksData.facebookLink} iconSize={calculateIconSize()} />
          )}
          {linksData.linkedinLink && (
            <SocialMediaButton icon={LinkedinLogo} altText="LinkedIn" link={linksData.linkedinLink} iconSize={calculateIconSize()} />
          )}
          {linksData.twitterLink && (
            <SocialMediaButton icon={TwitterLogo} altText="Twitter" link={linksData.twitterLink} iconSize={calculateIconSize()} />
          )}
          {linksData.youtubeLink && (
            <SocialMediaButton icon={YoutubeLogo} altText="YouTube" link={linksData.youtubeLink} iconSize={calculateIconSize()} />
          )}
          {linksData.tiktokLink && (
            <SocialMediaButton icon={TiktokLogo} altText="TikTok" link={linksData.tiktokLink} iconSize={calculateIconSize()} />
          )}
        </div>
      </div>
    </footer>
  );
}

export default Footer;
