import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Button,
  Drawer,
  notification,
  Collapse,
  Menu,
} from 'antd';
import { useAuth } from '../contexts/AuthContext';
import HamburguerLines from '../images/open_menu_icon_black.svg';
import HomepageIcon from '../images/homepage_icon.svg';
import UsersIcon from '../images/users_icon.svg';
import RolesIcon from '../images/roles_icon.svg';
import PersonalizeIcon from '../images/personalize_icon.svg';
import CandidatesIcon from '../images/candidates_icon.svg';
import SeasonsIcon from '../images/seasons_icon.svg';
import JobsIcon from '../images/jobs_icon.svg';
import DepartmentsIcon from '../images/departments_icon.svg';
import ProfileIcon from '../images/profile_icon.svg';
import LogoutIcon from '../images/logout_icon_white.svg';
import TranslateButton from './TranslateButton';
import axios from '../axios';
import '../css/navbar.css';

import { AbilityContext } from '../Abilities/Can';

const { Panel } = Collapse;

function NavbarMobile() {
  const companyID = 1;
  const [t] = useTranslation('global');
  const [open, setOpen] = useState(false);
  const [darkLogo, setDarkLogo] = useState('');
  const ability = useContext(AbilityContext);
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [userType, setUserType] = useState(null);
  const navigate = useNavigate();
  const { logout } = useAuth();

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get(
      '/api/user/profile',
    )
      .then((response) => {
        if (response.status === 200) {
          setUserId(response.data.user.id);
          setUserType(response.data.type);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleLogout = async () => {
    try {
      const response = await axios.post(
        '/api/user/logout',
      );
      if (response.status === 200) {
        logout();
        navigate('/login');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // Navbar items
  const items = [
    {
      label: t('menu.homepage'), key: '1', icon: <img src={HomepageIcon} alt="homepage icon" className="navbar-mobile-icon" />, link: '/', canAccess: () => true,
    },
    {
      label: t('menu.profile'), key: '2', icon: <img src={ProfileIcon} alt="profile icon" className="navbar-mobile-icon" />, link: ability.can('update', 'Users') ? `/user/${userId}` : '/profile', canAccess: () => userType === 'user',
    },
    {
      label: t('menu.profile'), key: '2', icon: <img src={ProfileIcon} alt="profile icon" className="navbar-icon" />, link: `/candidate/${userId}`, canAccess: () => userType === 'candidate',
    },
    {
      label: t('menu.users'), key: '3', icon: <img src={UsersIcon} alt="users icon" className="navbar-icon-collapse users-icon" />, link: '/users', canAccess: () => ability.can('read', 'Users') && userType === 'user',
    },
    {
      label: t('menu.roles'), key: '4', icon: <img src={RolesIcon} alt="roles icon" className="navbar-icon-collapse" />, link: '/roles', canAccess: () => ability.can('read', 'Roles'),
    },
    {
      label: t('menu.personalize'), key: '5', icon: <img src={PersonalizeIcon} alt="personalize icon" className="navbar-icon-collapse2" />, link: '/settings', canAccess: () => ability.can('read', 'Users') && userType === 'user',
    },
    {
      label: t('menu.departments'), key: '6', icon: <img src={DepartmentsIcon} alt="candidates icon" className="navbar-icon-collapse" />, link: '/departments', canAccess: () => ability.can('read', 'Departments'),
    },
    {
      label: t('menu.seasons'), key: '7', icon: <img src={SeasonsIcon} alt="seasons icon" className="navbar-icon-collapse2" />, link: '/seasons', canAccess: () => ability.can('read', 'Seasons'),
    },
    {
      label: t('menu.jobs'), key: '8', icon: <img src={JobsIcon} alt="jobs icon" className="navbar-icon-collapse2" />, link: '/jobs', canAccess: () => ability.can('read', 'Jobs'),
    },
    {
      label: t('menu.application_forms'), key: '9', icon: <img src={CandidatesIcon} alt="applicationForms icon" className="navbar-icon-collapse2" />, link: '/applicationForms', canAccess: () => ability.can('read', 'Application Forms'),
    },
  ];

  const showMenu = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  const currentKey = items.find((item) => item.link === location.pathname)?.key;

  return (
    <div className="navbar-mobile-container">
      <div className="navbar-mobile-header">
        <img src={darkLogo} alt="logo" className="logo-navbar-mobile" />
        <Button
          type="text"
          className="open-menu-mobile"
          icon={<img src={HamburguerLines} className="open-menu-icon" alt="open-menu" />}
          onClick={showMenu}
        />
      </div>
      <Drawer className="navbar-mobile-drawer" onClose={onClose} open={open} placement="right">
        <TranslateButton className="translate-button-navbar-mobile" />
        <Menu theme="dark" mode="inline" selectedKeys={[currentKey]}>
          {items.slice(0, 3).map((item) => (
            item.canAccess() && (
              <Menu.Item key={item.key} icon={item.icon}>
                <Link to={item.link}>{item.label}</Link>
              </Menu.Item>
            )
          ))}
        </Menu>
        { userType === 'user' && (ability.can('read', 'Users') || ability.can('read', 'Roles') || ability.can('read', 'all')) ? (
          <Collapse>
            <Panel
              header={
              (
                <div className="panel-header-container">
                  <img src={RolesIcon} alt="roles icon" className="panel-navbar-icon" />
                  <span className="panel-header">{t('menu.general')}</span>
                </div>
              )
            }
              key="1"
            >
              <Menu mode="inline" selectedKeys={[currentKey]}>
                {items.filter((item) => ['3', '4', '6'].includes(item.key)).map((item) => (
                  item.canAccess() && (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link className="panel-link-title" to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  )
                ))}
              </Menu>
            </Panel>
          </Collapse>
        ) : null}
        { userType === 'user' && (ability.can('read', 'Seasons') || ability.can('read', 'Jobs') || ability.can('read', 'Application Forms')) ? (
          <Collapse>
            <Panel
              header={
              (
                <div className="panel-header-container">
                  <img src={CandidatesIcon} alt="roles icon" className="panel-navbar-icon" />
                  <span className="panel-header">{t('menu.recruitment')}</span>
                </div>
              )
            }
              key="1"
            >
              <Menu mode="inline" selectedKeys={[currentKey]}>
                {items.filter((item) => ['8', '7', '9'].includes(item.key)).map((item) => (
                  item.canAccess() && (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link className="panel-link-title" to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  )
                ))}
              </Menu>
            </Panel>
          </Collapse>
        ) : null}
        { userType === 'user' && ability.can('read', 'Users') ? (
          <Collapse>
            <Panel
              header={
              (
                <div className="panel-header-container">
                  <img src={PersonalizeIcon} alt="roles icon" className="panel-navbar-icon" />
                  <span className="panel-header">{t('menu.personalize')}</span>
                </div>
              )
            }
              key="1"
            >
              <Menu mode="inline" selectedKeys={[currentKey]}>
                {items.filter((item) => ['5'].includes(item.key)).map((item) => (
                  item.canAccess() && (
                    <Menu.Item key={item.key} icon={item.icon}>
                      <Link className="panel-link-title" to={item.link}>{item.label}</Link>
                    </Menu.Item>
                  )
                ))}
              </Menu>
            </Panel>
          </Collapse>
        ) : null}
        <div className="footer-menu">
          <button type="button" className="logout-button-candidate" onClick={handleLogout}>
            <img src={LogoutIcon} className="logout-icon" alt="logout" />
            <span>{t('auth.logout')}</span>
          </button>
        </div>
      </Drawer>
    </div>
  );
}

export default NavbarMobile;
