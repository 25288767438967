import React, {
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import authUserStore from '../stores/authUserStore';
import { AbilityContext } from '../Abilities/Can';
import axios from '../axios';

const PermissionContext = createContext();

export function usePermissions() {
  return useContext(PermissionContext);
}

export function PermissionProvider({ children }) {
  const authUser = authUserStore((state) => state.authUser);
  const setAuthUser = authUserStore((state) => state.setAuthUser);
  const userType = authUserStore((state) => state.userType);
  const setUserType = authUserStore((state) => state.setUserType);
  const [ability, setAbility] = useState(() => createMongoAbility([]));

  useEffect(() => {
    const fetchPermissions = async () => {
      try {
        const response = await axios.get('/api/user/profile');
        setUserType(response.data.type);
        if (response.data.type === 'user') {
          const permissionsResponse = await axios.get(`/api/user/permission/${response.data.user.id}`);
          const permissions = permissionsResponse.data;
          setAuthUser({ permissions });

          const { can, rules } = new AbilityBuilder(createMongoAbility);
          permissions.forEach((permission) => {
            can(permission.action, permission.subject);
          });
          setAbility(createMongoAbility(rules));
        } else if (response.data.type === 'candidate') {
          setUserType(response.data.type);
          setAuthUser({ permissions: [] });
          setAbility(createMongoAbility([]));
        }
      } catch (error) {
        console.error('Failed to fetch permissions:', error);
      }
    };

    if (authUser.permissions.length === 0 && userType !== 'candidate') {
      fetchPermissions();
    } else {
      const { can, rules } = new AbilityBuilder(createMongoAbility);
      authUser.permissions.forEach((permission) => {
        can(permission.action, permission.subject);
      });
      setAbility(createMongoAbility(rules));
    }
  }, [authUser.permissions, setAuthUser, setUserType, userType]);

  return (
    <PermissionContext.Provider value={ability}>
      <AbilityContext.Provider value={ability}>
        {children}
      </AbilityContext.Provider>
    </PermissionContext.Provider>
  );
}

PermissionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PermissionContext };
export default PermissionProvider;
