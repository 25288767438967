import React, { useEffect, useState } from 'react';
import { notification, Input } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../../css/App.css';
import axios from '../../axios';
import GoogleLogo from '../../images/google_icon.svg';
import ExternalAuthButton from './ExternalAuthButton';
import PasswordForm from './PasswordForm';
import TranslateButton from '../TranslateButton';

function RegisterForm() {
  const navigate = useNavigate();
  const [submitted, setSubmitted] = useState(false);
  const [passedRequirements, setPassedRequirements] = useState(false);
  const companyID = '1';
  const [currentSeasonId, setCurrentSeasonId] = useState('');
  const [applicationFormId, setApplicationFormId] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
    candidateInfo: [],
    phases: [],
    companyID,
    seasonID: currentSeasonId,
  });

  const [t] = useTranslation('global');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  useEffect(() => {
    axios.get('api/season/current/applicationFormId', { params: { companyID } })
      .then((response) => {
        setApplicationFormId(response.data.applicationFormId);
      })
      .catch((error) => {
        openNotificationWithIcon('error', t('auth.fetch_error'));
        console.error(error);
      });

    axios.get('/api/season/current', { params: { companyID } })
      .then((response) => {
        setCurrentSeasonId(response.data.id);
      })
      .catch((error) => {
        openNotificationWithIcon('error', t('auth.fetch_error'));
        console.error(error);
      });
  }, []);

  useEffect(() => {
    if (currentSeasonId) {
      axios.get(`api/season/${currentSeasonId}/phases`)
        .then((response) => {
          const phases = response.data.map((phase) => ({
            id: phase.id,
            name: phase.name,
            current: phase.current,
            status: phase.current ? 'ongoing' : 'pending',
          }));
          setFormData((prevFormData) => ({
            ...prevFormData,
            phases,
          }));
        })
        .catch((error) => {
          openNotificationWithIcon('error', t('auth.fetch_error'));
          console.error(error);
        });
    }
  }, [currentSeasonId]);

  useEffect(() => {
    if (applicationFormId) {
      axios.get(`api/applicationForm/${applicationFormId}/fields`)
        .then((response) => {
          const candidateInfo = response.data.map((field, index) => ({
            id: index + 1,
            pt: field.pt,
            en: field.en,
            type: field.type,
            value: null,
            options: field.options || [],
            fileFormat: field.fileFormat || '',
            isRequired: field.isRequired,
            placeholder: field.placeholder || '',
          }));
          setFormData((prevFormData) => ({
            ...prevFormData,
            candidateInfo,
          }));
        })
        .catch((error) => {
          openNotificationWithIcon('error', t('auth.fetch_error'));
          console.error(error);
        });
    }
  }, [applicationFormId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (!passedRequirements) {
      openNotificationWithIcon('error', t('auth.requirements_not_met'));
      return;
    }
    try {
      await axios.post(
        '/api/user/register',
        formData,

      );
      openNotificationWithIcon('success', t('auth.register_success'));
      navigate('/');
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 400:
            openNotificationWithIcon('error', t('auth.register_400'));
            break;
          case 401:
            openNotificationWithIcon('error', t('auth.register_401'));
            break;
          case 402:
            openNotificationWithIcon('error', t('auth.register_402'));
            break;
          default:
            openNotificationWithIcon('error', error.response.data.message || t('auth.register_error'));
        }
      } else {
        openNotificationWithIcon('error', t('auth.register_network_error'));
      }
    }
  };
  return (
    <div className="auth-container">
      <h1 className="auth-title">
        {t('auth.register')}
        <TranslateButton />
      </h1>
      <form className="input-container" onSubmit={handleSubmit}>
        <Input
          type="text"
          className="input-field-register"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder={t('auth.name')}
          bordered={false}
        />
        <Input
          type="email"
          className="input-field-register"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder={t('auth.email')}
          bordered={false}
        />
        <PasswordForm
          design="1"
          submitted={submitted}
          password={formData.password}
          onPasswordChange={(newPassword) => setFormData({ ...formData, password: newPassword })}
          confirmPassword={formData.confirmPassword}
          onConfirmPasswordChange={
            (newPassword) => setFormData({ ...formData, confirmPassword: newPassword })
          }
          onRequirementsChange={(e) => setPassedRequirements(e)}
        />
        <button className="auth-button" type="submit">{t('auth.register')}</button>
      </form>
      <ExternalAuthButton linkProp={`${process.env.REACT_APP_API_BASE_URL}/api/user/auth/google`} iconProp={GoogleLogo} labelProp={t('auth.continue_with_google')} />
    </div>
  );
}

export default RegisterForm;
