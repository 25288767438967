import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { createMongoAbility, AbilityBuilder } from '@casl/ability';
import axios from '../axios';
import { AbilityContext } from '../Abilities/Can';
// import { useAuth } from '../contexts/AuthContext';
import AboutUs from '../components/AboutUs';
import Offers from '../components/Offers';
import Opportunities from '../components/Opportunities';
import Recruitment from '../components/Recruitment';
import Testimonials from '../components/Testimonials';
import Footer from '../components/Footer';
import TranslateButton from '../components/TranslateButtonHome';
import LoginIcon from '../images/login_icon.svg';
import UserIcon from '../images/candidate_icon.svg';
import '../css/home.css';

function Home() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [t] = useTranslation('global');
  const [userId, setUserId] = useState();
  const [userType, setUserType] = useState();
  const ability = useContext(AbilityContext);

  useEffect(() => {
    axios.get(
      '/api/user/profile',
    )
      .then((response) => {
        if (response.status === 200) {
          setIsLoggedIn(true);
          setUserId(response.data.user.id);
          setUserType(response.data.type);
          axios.get(`/api/user/permission/${response.data.user.id}`).then((permissionResponse) => {
            const { can, rules } = new AbilityBuilder(createMongoAbility);
            permissionResponse.data.forEach((permission) => {
              can(permission.action, permission.subject);
            });
            ability.update(rules);
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const candidateArea = async () => {
    if (isLoggedIn) {
      // Go to user page
      if (userType === 'user' && ability.can('update', 'Users')) {
        navigate(`user/${userId}`);
      } else if (userType === 'user') {
        navigate('/profile');
      } else if (userType === 'candidate') {
        navigate(`/candidate/${userId}`);
      }
    }
  };

  return (
    <div className="home-page-container">
      <div className="homepage-header">
        {isLoggedIn ? (
          <button type="button" className="candidate-area-button" onClick={candidateArea}>
            <img src={UserIcon} className="user-icon" alt="user" />
            {userType === 'candidate' ? t('opportunities.candidate_area_button') : t('opportunities.user_area_button')}
          </button>
        ) : (
          <button
            type="button"
            className="login-button"
            onClick={() => navigate('/login')}
          >
            <img src={LoginIcon} className="login-icon" alt="login" />
            {t('auth.login')}
          </button>
        )}
        <TranslateButton className="translate-button-home" />
      </div>
      <AboutUs />
      <Opportunities />
      <Recruitment />
      <Offers />
      <Testimonials />
      <Footer />
    </div>
  );
}

export default Home;
