import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/opportunities.css';

function Opportunities() {
  const [t] = useTranslation('global');

  return (
    <div className="opportunities">
      <div className="opportunities-gradient-div">
        <h1 className="opportunities-title">{t('opportunities.title')}</h1>
        <div className="gradient-line-opportunities" />
      </div>
      <div className="opportunities-component">
        <div className="opportunities-card-div">
          <p className="opportunities-card">
            Que oportunidades temos para ti? Se procuras pessoas com a mesma
            ambição que tu, não tenhas dúvidas. Junta-te a nós ao submeter o teu currículo!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Opportunities;
