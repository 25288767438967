import React from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { IoIosArrowBack } from 'react-icons/io';
import PropTypes from 'prop-types';
import PhoneCallLogo from '../images/phone-call.svg';
import GroupLogo from '../images/group.svg';
import InterviewLogo from '../images/interview.svg';
import ProblemLogo from '../images/technical-problem.svg';
import languageStore from '../stores/languageStore';
import '../css/createPhasesWrap.css';

const { Panel } = Collapse;

function CreatePhasesWrap({ phasesData, setWrapPage, setCurrentPage }) {
  const [t] = useTranslation('global');
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const options = { day: '2-digit', month: '2-digit', year: '2-digit' };
    return date.toLocaleDateString('en', options);
  };

  const logoSelector = (name) => {
    switch (name[selectedLanguage]) {
      case 'Entrevista Telefónica':
      case 'Phone Interview':
        return PhoneCallLogo;
      case 'Dinâmica de Grupo':
      case 'Group Dynamic':
        return GroupLogo;
      case 'Problema Técnico':
      case 'Technical Problem':
        return ProblemLogo;
      case 'Entrevista Presencial':
      case 'In-Person Interview':
        return InterviewLogo;
      default:
        return '';
    }
  };

  const handleBack = (event) => {
    event.preventDefault();
    setCurrentPage(1);
    setWrapPage(false);
  };

  return (
    <div className="create-phases-wrap-page">
      <div className="create-phase-header">
        <button onClick={handleBack} className="go-back-btn" type="button" aria-label="Go back">
          <IoIosArrowBack />
        </button>
        <h1 className="create-phase-title">{t('season.phases')}</h1>
      </div>
      {phasesData.map((phase) => (
        <div className="create-phases-wrap-body">
          <Collapse accordion ghost>
            <Panel
              header={(
                <div className="panel-header">
                  <span>{phase.name}</span>
                  <img src={logoSelector(phase.type)} alt="logo" className="phase-logo" />
                </div>
              )}
              key={phase.id}
            >
              <div className="phase-info">
                <div className="phase-description-div">
                  <p className="phase-description-label">
                    {t('season.phase_description_label')}
                  </p>
                  <p className="phase-description-text">{phase.description}</p>
                </div>
                <div className="phase-dates-div">
                  <div className="phase-begin-date-div">
                    <p className="phase-begin-date-label">
                      {t('season.start_date')}
                    </p>
                    <div className="phase-begin-date-text-div">
                      <FaRegCalendarAlt className="phase-begin-date-icon" />
                      <p className="phase-begin-date-text">
                        {formatDate(phase.beginDate)}
                      </p>
                    </div>
                  </div>
                  <div className="phase-end-date-div">
                    <p className="phase-end-date-label">
                      {t('season.end_date')}
                    </p>
                    <div className="phase-end-date-text-div">
                      <FaRegCalendarAlt className="phase-end-date-icon" />
                      <p className="phase-end-date-text">
                        {formatDate(phase.endDate)}
                      </p>
                    </div>
                  </div>
                </div>
                {phase.phoneInterviewCandidatesAvailability === true && (
                  <div className="phase-phone-interview-div">
                    <p className="phase-phone-interview-label">
                      {t(
                        'season.phase_phone_interview_candidates_availability'
                        ,
                      )}
                      :
                    </p>
                    <p className="phase-phone-interview-text">
                      {t('season.yes')}
                    </p>
                  </div>
                )}
                {phase.groupDynamicTime !== '' && (
                  <div className="phase-group-dynamic-div">
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_group_dynamic_time')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {phase.groupDynamicTime}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_group_dynamic_candidates_number')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {phase.groupDynamicCandidates}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_group_dynamic_interviewers_number')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {phase.groupDynamicInterviewers}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_group_dynamic_present_members')}
                        :
                      </p>
                      {phase.groupDynamicPresentMembers.map((member) => (
                        member === 'membersDiversity' ? (
                          <p className="phase-group-dynamic-text">
                            {t('season.phase_group_dynamic_present_members_diversity')}
                          </p>
                        ) : (
                          <p className="phase-group-dynamic-text">
                            {t('season.phase_group_dynamic_present_members_directors')}
                          </p>
                        )
                      ))}
                    </div>
                  </div>
                )}
                {phase.phaseTechnicalProblemSize !== '' && (
                  <div className="phase-group-dynamic-div">
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_technical_problem_file_submission')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {t('season.yes')}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_technical_problem_file_size')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {phase.phaseTechnicalProblemSize}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_technical_problem_file_format')}
                        :
                      </p>
                      {phase.phaseTechnicalProblemFormat.map((format) => (
                        <p className="phase-group-dynamic-text">{format}</p>
                      ))}
                    </div>
                  </div>
                )}
                {phase.phaseInPersonInterviewTime !== '' && (
                  <div className="phase-group-dynamic-div">
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_in_person_interview_time')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {phase.phaseInPersonInterviewTime}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_group_dynamic_candidates_number')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {phase.phaseInPersonInterviewCandidates}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_group_dynamic_interviewers_number')}
                        :
                      </p>
                      <p className="phase-group-dynamic-text">
                        {phase.phaseInPersonInterviewInterviewers}
                      </p>
                    </div>
                    <div className="phase-group-dynamic-label-div">
                      <p className="phase-group-dynamic-label">
                        {t('season.phase_group_dynamic_present_members')}
                        :
                      </p>
                      {phase.phaseInPersonInterviewPresentMembers.map(
                        (member) => (
                          member === 'directors' ? (
                            <p className="phase-group-dynamic-text">
                              {t('season.phase_group_dynamic_present_members_directors')}
                            </p>
                          ) : (
                            <p className="phase-group-dynamic-text">{member}</p>
                          )
                        ),
                      )}
                    </div>
                  </div>
                )}
              </div>
            </Panel>
          </Collapse>
        </div>
      ))}
    </div>
  );
}

CreatePhasesWrap.propTypes = {
  phasesData: PropTypes.isRequired,
  setWrapPage: PropTypes.isRequired,
  setCurrentPage: PropTypes.isRequired,
};

export default CreatePhasesWrap;
