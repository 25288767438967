import React from 'react';
import PropTypes from 'prop-types';

function Offer({ name, photo, description }) {
  return (
    <div className="offer-div">
      <div className="offer-photo-div">
        <img className="offer-photo" src={photo} alt="offer-icon" />
      </div>
      <div className="offer-content">
        <h3 className="offer-name">{name}</h3>
        <p className="offer-description">{description}</p>
      </div>
    </div>
  );
}

Offer.propTypes = {
  name: PropTypes.isRequired,
  photo: PropTypes.isRequired,
  description: PropTypes.isRequired,
};

export default Offer;
