import React, { useState, useEffect, useContext } from 'react';
import {
  Col, Row, notification,
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useNavigate, useParams,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from '../axios';
import ProfilePlaceholder from '../images/user_pic_placeholder.svg';
import EditIcon from '../images/edit_icon.svg';
import DeleteIcon from '../images/delete_icon.svg';
import Navbar from '../components/Navbar';
import NavbarMobile from '../components/NavbarMobile';
import ConfirmDelete from '../components/ConfirmDelete';
import { Can, AbilityContext } from '../Abilities/Can';
import languageStore from '../stores/languageStore';
import '../css/userProfile.css';

function UserProfile({ adminView }) {
  const navigate = useNavigate();
  const [t] = useTranslation('global');
  const selectedLanguage = languageStore.getState().currentLanguage || 'pt';
  const companyID = 1;
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [gender, setGender] = useState('');
  const [department, setDepartment] = useState('');
  const [role, setRole] = useState('');
  const [, setIsLoaded] = useState(false);
  const [profileImage, setProfileImage] = useState('');
  const [darkLogo, setDarkLogo] = useState('');
  const [loadingImage, setLoadingImage] = useState(true);
  const ability = useContext(AbilityContext);
  const [currentUserID, setCurrentUserID] = useState(0);
  const [showConfirmation, setShowConfirmation] = useState(false);
  let userID = 0;
  if (adminView) {
    const { id } = useParams();
    userID = id;
  }

  const endpointURL = adminView ? `/api/user/${userID}` : '/api/user/profile';
  const editProfileURL = adminView ? `/user/${userID}/edit` : '/profile/edit';

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: 'Success',
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: 'Error',
        description: message,
      });
    }
  };
  useEffect(() => {
    axios.get(
      endpointURL,
    )
      .then((response) => {
        setName(response.data.user.name);
        setEmail(response.data.user.email);
        const genderKey = String(response.data.user.gender);
        setGender(genderKey);
        setDepartment(response.data.user.Department.name[selectedLanguage]);
        setProfileImage(response.data.user.profileImage || ProfilePlaceholder);
        if (adminView) {
          setRole(response.data.user.Role.name[selectedLanguage]);
        }
        setIsLoaded(true);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          openNotificationWithIcon('error', t('auth.need_login'));
          navigate('/login');
        } else if (error.response.status === 403) {
          openNotificationWithIcon('error', t('auth.not_authorized'));
          navigate('/login');
        } else if (error.response.status === 404) {
          openNotificationWithIcon('error', t('profile.not_found'));
          navigate('/');
        }
      });
  }, [languageStore.getState().currentLanguage]);

  if (endpointURL === `/api/user/${userID}`) {
    axios.get('/api/user/profile')
      .then((response) => {
        setCurrentUserID(response.data.user.id);
      }).catch((error) => {
        console.error('Error fetching user:', error);
      });
  }

  useEffect(() => {
    axios.get(`/api/settings/company/${companyID}/logos`)
      .then((response) => {
        setDarkLogo(response.data.companyLogoDark);
      })
      .catch((error) => {
        console.error('Error fetching logos:', error);
        openNotificationWithIcon('error', t('settings.logo_fetch_failed'));
      });
  }, []);

  const deleteUser = () => {
    axios.delete(`/api/user/${userID}`)
      .then(() => {
        openNotificationWithIcon('success', t('profile.delete_success'));
        navigate('/users');
      })
      .catch((error) => {
        console.error('Error deleting User:', error);
        openNotificationWithIcon('error', t('profile.delete_failed'));
      });
  };

  return (
    <div>
      <Row className="user-profile-container">
        <NavbarMobile />
        <Navbar />
        <Col xs={24} sm={24} md={9} className="user-col1">
          <h1 className="user-profile-title-mobile">{t('profile.user_profile')}</h1>
          {loadingImage && <span className="loader" /> }
          {}
          <img
            src={profileImage}
            alt="profile"
            className="profile-img"
            onLoad={() => setLoadingImage(false)}
            style={{ display: loadingImage ? 'none' : 'block' }}
          />
          {!adminView ? (
            <button onClick={() => navigate(editProfileURL)} className="edit-profile-btn" type="button">
              {t('profile.edit_profile')}
            </button>
          ) : (
            <div className="profile-buttons">
              <Can do="update" on="Users">
                <button onClick={() => navigate(editProfileURL)} className="edit-profile-btn" type="button">
                  {t('profile.edit_profile')}
                  <img src={EditIcon} alt="edit" className="edit-icon-logo" />
                </button>
              </Can>
              {userID.toString() !== currentUserID.toString() && (
                <Can do="delete" on="Users">
                  <button onClick={() => setShowConfirmation(true)} className="delete-profile-btn" type="button">
                    <img src={DeleteIcon} alt="delete" className="delete-icon-logo" />
                  </button>
                </Can>
              )}
            </div>
          )}
        </Col>
        <Col xs={24} sm={24} md={15} className="user-col2">
          <img src={darkLogo} alt="logo" className="profile-logo desktop" id="logo-desktop" />
          <div className="user-col2-content">
            <div className="user-info">
              <h1 className="user-profile-title-desktop">{t('profile.user_profile')}</h1>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.name')}</h3>
                <p className="user-info-text">{name}</p>
              </div>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.email')}</h3>
                <p className="user-info-text">{email}</p>
              </div>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.gender')}</h3>
                <p className="user-info-text">{t(`gender.${gender}`)}</p>
              </div>
              <div className="user-info-container">
                <h3 className="user-info-title">{t('profile.department')}</h3>
                <p className="user-info-text">{department}</p>
              </div>
              {adminView && ability.can('update', 'Users') && (
                <div className="user-info-container">
                  <h3 className="user-info-title">{t('profile.role')}</h3>
                  <p className="user-info-text">{role}</p>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <ConfirmDelete
        open={showConfirmation}
        setOpen={setShowConfirmation}
        onConfirm={deleteUser}
        name={name}
      />
    </div>
  );
}

UserProfile.propTypes = {
  adminView: PropTypes.bool.isRequired,
};

export default UserProfile;
