import React from 'react';
import Carousel from 'react-multi-carousel';
import { useTranslation } from 'react-i18next';
import 'react-multi-carousel/lib/styles.css';
import Testimonial from './Testimonial';
import CustomLeftArrow from './CustomLeftArrow';
import CustomRightArrow from './CustomRightArrow';
import photo from '../images/user_pic_placeholder.svg';
import '../css/testimonials.css';

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const testimonialsData = [
  {
    name: 'Pedro Cova',
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    photo,
  },
  {
    name: 'João Silva',
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    photo,
  },
  {
    name: 'António Machado',
    description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
    photo,
  },
];

function Testimonials() {
  const [t] = useTranslation('global');
  const carouselClass = testimonialsData.length <= 2 ? 'centered-carousel' : '';

  return (
    <div className="testimonials">
      <div className="testimonials-gradient-div">
        <h1 className="testimonials-title">{t('testimonials.title')}</h1>
        <div className="gradient-line-testimonials" />
      </div>
      <div className={`testimonials-carousel ${carouselClass}`}>
        <Carousel
          responsive={responsive}
          infinite
          autoPlay
          autoPlaySpeed={3000}
          removeArrowOnDeviceType={['mobile']}
          customLeftArrow={<CustomLeftArrow />}
          customRightArrow={<CustomRightArrow />}
        >
          {testimonialsData.map((testimonial) => (
            <Testimonial
              name={testimonial.name}
              description={testimonial.description}
              photo={testimonial.photo}
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

export default Testimonials;
