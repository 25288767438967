import React, { useState } from 'react';
import { Input, notification } from 'antd';
import { useTranslation } from 'react-i18next';
import axios from '../../axios';
import '../../css/App.css';
import LockIcon from '../../images/lock_icon.svg';

function RecoverPasswordForm() {
  const [email, setEmail] = useState('');
  const [t] = useTranslation('global');

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleSubmit = async (e) => {
    // make request to backend
    e.preventDefault();
    try {
      const response = await axios.post(
        '/api/user/recover-password',
        { email },
      );

      if (response.status === 200) {
        openNotificationWithIcon('success', t('recover_password.email_sent'));
        setEmail('');
      } else if (response.status === 404) {
        openNotificationWithIcon('error', t('recover_password.email_not_found_1') + email + t('recover_password.email_not_found_2'));
      } else {
        // Get the error message from the response and display notification
        const errorData = await response.json();
        openNotificationWithIcon('error', errorData);
      }
    } catch (error) {
      openNotificationWithIcon('error', 'An error occured during login');
    }
  };

  return (
    <div className="auth-container">
      <img src={LockIcon} alt="Lock icon" className="lock-icon" />
      <h1 className="recover-password-title">{t('recover_password.title')}</h1>
      <div className="recover-password-instructions">
        {t('recover_password.instructions')}
      </div>
      <form className="input-container" onSubmit={handleSubmit}>
        <Input
          className=""
          placeholder={t('auth.email')}
          type="email"
          id="recover-email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          allowClear
        />
        <button className="auth-button" type="submit">{t('recover_password.send_email_button')}</button>
        <p><a className="auth-link" href="/login">{t('applicationForm.cancel')}</a></p>
      </form>
    </div>
  );
}

export default RecoverPasswordForm;
