import React, { useState } from 'react';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams, useNavigate } from 'react-router-dom';
import axios from '../../axios';
import '../../css/App.css';
import LockIcon from '../../images/lock_icon.svg';
import PasswordForm from './PasswordForm';

function ModifyPasswordForm() {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [submitted, setSubmitted] = useState('');
  const [passedRequirements, setPassedRequirements] = useState(false);
  const [t] = useTranslation('global');
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const navigate = useNavigate();

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      notification[type]({
        message: t('notification.success'),
        description: message,
      });
    } else if (type === 'error') {
      notification[type]({
        message: t('notification.error'),
        description: message,
      });
    }
  };

  const handleSubmit = async (e) => {
    // make request to backend
    e.preventDefault();
    setSubmitted(true);
    if (!passedRequirements) {
      openNotificationWithIcon('error', t('auth.requirements_not_met'));
      return;
    }
    try {
      const response = await axios.post(
        '/api/user/modify-password',
        { token, password },
      );
      if (response.status === 200) {
        openNotificationWithIcon('success', t('modify_password.password_changed'));
        navigate('/login');
      } else {
        // Get the error message from the response and display notification
        const errorData = await response.json();
        openNotificationWithIcon('error', errorData);
      }
    } catch (error) {
      openNotificationWithIcon('error', t('modify_password.invalid_token'));
    }
  };

  return (
    <div className="auth-container">
      <img src={LockIcon} alt="Lock icon" className="lock-icon" />
      <h1 className="recover-password-title">{t('modify_password.title')}</h1>
      <div className="recover-password-instructions">
        {t('modify_password.instructions')}
      </div>
      <form className="input-container" onSubmit={handleSubmit}>
        <PasswordForm
          design="2"
          submitted={submitted}
          password={password}
          onPasswordChange={(e) => setPassword(e)}
          confirmPassword={confirmPassword}
          onConfirmPasswordChange={(e) => setConfirmPassword(e)}
          onRequirementsChange={(e) => setPassedRequirements(e)}
        />
        <button className="auth-button" type="submit">{t('modify_password.change_password_button')}</button>
      </form>
    </div>
  );
}

export default ModifyPasswordForm;
